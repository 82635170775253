<template>
  <v-layout>
    <v-navigation-drawer v-model="navShow" app :mini-variant.sync="mini">
      <v-list-item color="grey lighten-5" class="px-2">
        <v-list-item-avatar>
          <v-icon>$logoSingle</v-icon>
        </v-list-item-avatar>
        <v-list-item-title :title="this.fullname" style="line-height: 2.2">
          {{ this.fullname }}
        </v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <font-awesome-icon
            :icon="['fas', 'arrow-circle-left']"
            style="font-size: 21px"
          ></font-awesome-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          v-for="subMenu in this.subMenus"
          :key="subMenu.name"
          :to="{ name: subMenu.name }"
        >
          <v-list-item-icon>
            <font-awesome-icon
              class="v-icon theme--light"
              :icon="subMenu.icon"
            ></font-awesome-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ subMenu.title }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link :to="{ name: 'signout' }" replace>
          <v-list-item-icon>
            <font-awesome-icon
              class="v-icon theme--light"
              :icon="['fas', 'sign-out-alt']"
            ></font-awesome-icon>
          </v-list-item-icon>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar dense elevation="2" app>
        <v-app-bar-nav-icon
          @click="navShow = !navShow"
          class="mr-xl-10 mr-lg-10 mr-md-10 mr-sm-10"
          v-if="this.$vuetify.breakpoint.mdAndDown"
        >
        </v-app-bar-nav-icon>
        <v-btn text :href="this.$shop('url')" target="_blank">
          Shop
        </v-btn>

        <v-btn text :href="this.$shop('config.contacts.customer_service')" target="_blank">
          Support
        </v-btn>

        <v-btn
          v-if="this.community_url && this.community_url.length"
          text
          :href="this.community_url"
          target="_blank"
        >
          Community
        </v-btn>
      </v-app-bar>
      <v-layout class="pa-3">
        <router-view/>
      </v-layout>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  name: "top-left-nav",
  data: function() {
    const subMenus = this.$router.options.routes
      .find(route => route.name === "userIndex")
      .children.map(subMenu => {
        return {
          name: subMenu.name,
          title: subMenu.meta.title,
          icon: subMenu.meta.icon
        };
      });
    return {
      community_url: this.$shop('config.urls.community'),
      mini: false,
      navShow: this.$vuetify.breakpoint.lgAndUp,
      subMenus
    };
  },
  computed: {
    fullname: function() {
      if(this.$user){
        const { firstName, lastName, username, id } = this.$user;

        if (firstName || lastName) {
          return `${firstName} ${lastName}`;
        } else if (username) {
          return username;
        }
        return id;
      }

      return null
    }
  }
};
</script>

<style scoped></style>
