<template>
  <v-flex>
    <v-skeleton-loader
      type="article, list-item-two-line, paragraph, actions"
      v-if="this.isPageLoading"
      class="mt-2"
    >
    </v-skeleton-loader>
    <v-card v-else flat>
      <v-card-title class="justify-center text-uppercase">
        Sign Up
      </v-card-title>
      <v-sheet class="font-italic font-weight-light" style="font-size: small">
        If you already signed up in <span class="text-capitalize">{{this.$shop("name")}}</span> App, please feel free to use the account to <router-link to="signin">sign in</router-link>.
      </v-sheet>
      <ValidationObserver ref="observer">
        <v-form :disabled="isSubmitting">
          <ValidationProvider
            v-slot="{ errors }"
            name="login email address"
            :rules="rules.id"
            :customMessages="valiMessages.id"
          >
            <v-text-field
              v-model.trim="model.username"
              label="Email"
              required
              :error-messages="errors"
              hint="Please fill in your login email address"
              autocomplete="off"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="login password"
            :rules="rules.password"
            :customMessages="valiMessages.password"
            vid="password"
          >
            <v-text-field
              v-model="model.password"
              label="Password"
              type="password"
              required
              :error-messages="errors"
              counter
              hint="Please fill in your login password"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="confirm password"
            :rules="rules.confirm_password"
            :customMessages="valiMessages.confirm_password"
          >
            <v-text-field
              v-model="model.confirm_password"
              label="Confirm Password"
              type="password"
              required
              :error-messages="errors"
              hint="Please fill in your login password again"
            ></v-text-field>
          </ValidationProvider>

          <v-row>
            <v-col cols="12" md="6" lg="8" class="d-flex align-center">
              I already had an account, please
              <router-link to="signin" class="ml-2">sign in</router-link>
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <v-checkbox
                v-model="model.opt_in_news"
                label="Opt in news letters"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="terms & policies"
                :rules="rules.agree_terms"
                :customMessages="valiMessages.agree_terms"
              >
                <v-checkbox
                  v-model="model.agree_terms"
                  type="checkbox"
                  class="mt-0"
                  :error-messages="errors"
                  required
                >
                  <template v-slot:label>
                    <div>
                      I agree to the
                      <router-link :to="{ name: 'services' }" target= '_blank'>
                        <span @click.stop>
                          Terms of Services & Privacy Statements
                        </span>
                      </router-link>
                    </div>
                  </template>
                </v-checkbox>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" outlined :loading="isSubmitting">
          Create Account
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "SignUp",
  data: () => ({
    isPageLoading: true,
    isSubmitting: false,
    model: {
      username: null,
      password: null,
      confirm_password: null,
      opt_in_news: true,
      agree_terms: true,
    },
    rules: null,
    valiMessages: null,
  }),
  methods: {
    async submit() {
      return this.$refs.observer.validate().then(async (valid) => {
        if (valid) {
          this.isSubmitting = true;
          const { data: res } = await this.$http.post("/user/signup", {
            username: this.model.username,
            password: this.model.password,
          });

          if (!res.errors || !res.errors.length) {
            const user = {
              username: this.model.username,
              email: this.model.username,
            };
            this.$store.commit("userContext/setUser", user);
            if(this.$gtag){
              this.$gtag.event("sign_up")
            }
            await this.$router.push({ name: "activate" });
          }

          this.isSubmitting = false;
        }

        return valid;
      });
    },
  },
  async mounted() {
    this.isPageLoading = true;
    const { data: rules } = await this.$http.post("/user/validationRules", {
      vType: "vee",
    });
    rules.password.required = "Please fill in your login password"
    this.rules = Object.assign({}, rules, {
      confirm_password: {
        required: "Please fill in your login password again",
        confirmed: {
          target: "password",
          message: "Confirmed password is different from the original one",
        },
      },
      agree_terms: {
        required: {
          allowFalse: false,
          message: "Please agree to the terms & policies before proceed",
        },
      },
    });
    this.valiMessages = this.$utils.normalizeRuleMessages(this.rules);
    this.isPageLoading = false;
  },
};
</script>
