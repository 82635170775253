var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":"","sm10":"","md8":"","lg6":"","xl5":""}},[(this.isPageLoading)?_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"article, list-item-two-line, paragraph, actions"}}):_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{attrs:{"disabled":_vm.isSubmitting}},[_c('ValidationProvider',{attrs:{"name":"gender","rules":_vm.rules.gender,"customMessages":_vm.valiMessages.gender},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":Object.entries(_vm.$server_configs.constants.genders).map(function (ref) {
var text = ref[0];
var value = ref[1];
return {text: text, value: value}}),"label":"Gender","error-messages":errors,"required":"","hint":"Please select your gender","clearable":""},model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"first name","rules":_vm.rules.firstName,"customMessages":_vm.valiMessages.firstName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name","required":"","error-messages":errors,"hint":"Please fill in your first name"},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"last name","rules":_vm.rules.lastName,"customMessages":_vm.valiMessages.lastName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name","required":"","error-messages":errors,"hint":"Please fill in your last name"},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"middle name","rules":_vm.rules.middleName,"customMessages":_vm.valiMessages.middleName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Middle Name","required":"","error-messages":errors,"hint":"Please fill in your middle name"},model:{value:(_vm.model.middleName),callback:function ($$v) {_vm.$set(_vm.model, "middleName", $$v)},expression:"model.middleName"}})]}}])}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"date of birth","rules":_vm.rules.DOB,"customMessages":_vm.valiMessages.DOB},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Birthday date","readonly":"","error-messages":errors},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"v-icon theme--light"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'calendar-alt']}})],1)]},proxy:true}],null,true),model:{value:(_vm.model.DOB),callback:function ($$v) {_vm.$set(_vm.model, "DOB", $$v)},expression:"model.DOB"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.showDOBPicker),callback:function ($$v) {_vm.showDOBPicker=$$v},expression:"showDOBPicker"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":_vm.minDOB},on:{"change":_vm.saveDOB},model:{value:(_vm.model.DOB),callback:function ($$v) {_vm.$set(_vm.model, "DOB", $$v)},expression:"model.DOB"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.saveDOB}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" Save Profile ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }