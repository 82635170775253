<template>
  <v-flex :style="this.user ? 'padding-bottom: 56px' : 'padding-bottom: 76px'">
    <v-flex v-bind="this.containerAttrs">
      <v-skeleton-loader
        type="article, list-item-two-line, paragraph, actions"
        v-if="this.isPageLoading"
        class="mt-2"
      >
      </v-skeleton-loader>
      <v-card v-else flat>
        <v-card-title class="text-uppercase">
          {{ this.$route.meta.title }}
        </v-card-title>
        <v-card-subtitle class="font-italic">
          We need your purchased order information to verify your product warranty, it's important to us to know how we can serve you better.
        </v-card-subtitle>
        <v-divider></v-divider>
        <ValidationObserver ref="observer">
          <v-form :disabled="isSubmitting || isVerifying" ref="form">
            <v-card-subtitle v-if="this.user">
              Registered Email:
              <span class="font-weight-bold">{{ this.email }}</span>
            </v-card-subtitle>
            <v-card-text>
              <ValidationProvider
                  v-slot="{ errors }"
                  name="your amazon purchased order id"
                  :rules="rules.amzOrderId"
                  vid="amzOrderId"
                  ref="orderIdValidator"
                  :customMessages="valiMessages.amzOrderId"
              >
                <v-text-field
                    v-model.trim="model.amzOrderId"
                    :disabled="needMarketplace || verified"
                    label="Amazon Order ID"
                    required
                    :error-messages="errors"
                    hint="Please provide your amazon purchased order id"
                ></v-text-field>
              </ValidationProvider>

              <template v-if="!this.user && this.verified">
                <ValidationProvider
                    v-slot="{ errors }"
                    name="your email address"
                    :rules="rules.email"
                    vid="email"
                    :customMessages="valiMessages.email"
                >
                  <v-text-field
                      v-model.trim="model.email"
                      label="Email address"
                      required
                      :error-messages="errors"
                      hint="Your confirmed warranty receipt will be sent to given email address"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <template v-if="needMarketplace">
                <v-sheet class="font-weight-light font-italic">
                  <p>
                    We've found multiple marketplaces subject to the given
                    amazon order id, to prevent ambiguity we need your further
                    action:
                  </p>
                  <span>
                    please select from which marketplace you purchased the
                    item(s)
                  </span>
                </v-sheet>
                <v-select
                    v-model="model.marketplaceId"
                    ref="select_marketplace"
                    :items="marketplaces"
                    label="Amazon marketplaces"
                    outlined
                    item-value="id"
                    :disabled="!!model.marketplaceId"
                    @change="marketplace_selected"
                    :item-text="
                    (item) =>
                      `${item.country.name} (${normalizeMarketplaceURL(
                        item.url
                      )})`
                  "
                    messages="Please select from which Amazon marketplace you purchased the product."
                ></v-select>
              </template>
            </v-card-text>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-flex>
    <v-fade-transition leave-absolute>
      <v-card shaped style="margin-top: 10px" v-if="tbl_data">
        <v-card-subtitle> Your order information </v-card-subtitle>

        <v-card-text>
          <v-sheet v-for="(marketplace, index) in tbl_data" :key="index">
            <h4 class="font-italic font-weight-light">
              {{ normalizeMarketplaceURL(marketplace.url) }}@
              <span class="font-italic font-weight-light">{{
                marketplace.country.name
              }}</span>
            </h4>

            <v-data-table
              :headers="tbl_headers"
              :items="marketplace.orders"
              disable-sort
              disable-filtering
              disable-pagination
              hide-default-footer
              item-key="orderItemId"
              :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
            >
            </v-data-table>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-fade-transition>
    <v-bottom-navigation fixed grow>
      <template v-if="tbl_data">
        <v-btn @click="submit" :loading="isSubmitting" class="app_btn deep-orange lighten-1">
          <span class="white--text"> Get Warranty </span>
        </v-btn>
      </template>
      <template v-else>
        <v-btn @click="verify" :loading="isVerifying" :disabled="verified" class="app_btn deep-orange lighten-1">
          <span :class="verified ? 'grey--text' : 'white--text'"> Verify </span>
        </v-btn>
      </template>
      <v-btn
        class="app_btn blue-grey lighten-5"
        @click="reset"
        v-if="verified"
        :disabled="isSubmitting || isVerifying"
      >
        <span> Reset </span>
      </v-btn>
    </v-bottom-navigation>
  </v-flex>
</template>

<script>
export default {
  name: "RegisterOrder",
  data: function () {
    const tbl_headers = [
      {
        text: "Item",
        value: "title",
        width: "30%",
      },
      {
        text: "ASIN",
        value: "ASIN",
      },
      {
        text: "Amount",
        value: "amount",
      },
      {
        text: "Purchased Date",
        value: "warranty.purchasedDate",
      },
      {
        text: "Warranty",
        value: "warranty.current_formated",
      },
      {
        text: "Extended Warranty",
        value: "warranty.extended_formated",
      },
    ];
    return {
      isVerifying: false,
      isSubmitting: false,
      isPageLoading: true,
      needMarketplace: false,
      verified: false,
      model: {
        amzOrderId: null,
        marketplaceId: null,
        email: null,
      },
      marketplaces: [],
      rules: {
        amzOrderId: {
          required: "Please provide your amazon purchased order id",
        },
        email:{
          required: "Please provide your email address",
          email: "invalid email address"
        }
      },
      tbl_data: null,
      valiMessages: null,
      user: this.$user,
      tbl_headers,
    };
  },
  computed: {
    email() {
      return this.user ? this.user.id : this.model.email;
    },
    containerAttrs() {
      return this.user
        ? {
            xs12: true,
            sm10: true,
            md8: true,
            lg6: true,
            xl5: true,
          }
        : null;
    },
  },
  methods: {
    async verify() {
      return this.$refs.orderIdValidator.validate().then(async ({ valid }) => {
        if (valid) {
          this.isVerifying = true;
          const { data: res } = await this.$http.post("/orders/register", {
            amzOrderId: this.model.amzOrderId,
            marketplaceId: this.model.marketplaceId,
          });
          const { toRegister: marketplaces, errors } = res;

          if (errors && errors.length) {
            this.$refs.observer.setErrors({
              amzOrderId: errors.join(";"),
            });
            if (this.$gtag) {
              this.$gtag.exception({
                description: errors.join(";"),
                fatal: false,
              });
            }
          } else {
            if (this.$gtag) {
              this.$gtag.event("order_register_verify", {
                content_id: this.$route.query.content_gid,
                origin: this.$route.query.source,
              });
            }
            //if multiple marketplaces returned promote for further selection
            if (marketplaces.length > 1) {
              this.marketplaces = marketplaces;
              this.needMarketplace = true;
            }
            //single marketplace, all good
            else {
              this.tbl_data = marketplaces.map((marketplace) => {
                marketplace.orders = marketplace.orders.reduce(
                  (accu, current) => {
                    for (const orderItem of current.orderItems) {
                      orderItem.amount = orderItem.itemPriceAmount
                        ? `${orderItem.itemPriceAmount} ${orderItem.itemPriceCurrency} * ${orderItem.quantityOrdered}`
                        : "N/A";
                    }
                    accu = accu.concat(current.orderItems);
                    return accu;
                  },
                  []
                );

                return marketplace;
              });
            }

            this.verified = true;
          }

          this.isVerifying = false;
        }
      });
    },
    async submit() {
      return this.$refs.observer.validate().then(async (valid) => {
        if(valid){
          this.isSubmitting = true;
          const { data: res } = await this.$http.post("/orders/register/save", {
            amzOrderId: this.model.amzOrderId,
            email: this.model.email,
            marketplaceId: this.model.marketplaceId,
            customerService: this.$shop('config.contacts.customer_service'),
            source: this.$route.query.source,
            event_notify_url: this.$route.query.event_notify_url,
            content_gid: this.$route.query.content_gid,
          });

          if (!res.errors || !res.errors.length) {
            if (this.$gtag) {
              this.$gtag.event("order_registered", {
                content_id: this.$route.query.content_gid,
                origin: this.$route.query.source,
              });
            }
            await this.$router.replace({
              name: "notice",
              params: {
                title: "Order Registered Successfully",
                type: "success",
                content: `You extended warranty has been activated, please check your email <span class="font-weight-bold font-italic">${this.email}</span> inbox for detail about your warranty information.`,
                actions: ["home"],
              },
            });
          }
          this.isSubmitting = false;
        }
      })
    },
    async marketplace_selected() {
      if (this.$gtag) {
        this.$gtag.event("order_register_select_mkt", {
          marketplaceId: this.model.marketplaceId,
          content_id: this.$route.query.content_gid,
          origin: this.$route.query.source,
        });
      }
      await this.verify();
      this.$refs.select_marketplace.blur();
    },
    normalizeMarketplaceURL(url) {
      return url.replace("https://www.", "").replace("/", "");
    },
    reset() {
      this.marketplaces = [];
      this.tbl_data = null;
      this.needMarketplace = false;
      this.verified = false;
      this.$refs.form.reset();
      this.$refs.observer.reset();
    },
  },
  mounted() {
    this.isPageLoading = true;
    this.valiMessages = this.$utils.normalizeRuleMessages(this.rules);
    this.isPageLoading = false;
  },
};
</script>

<style scoped>
.app_btn{
  font-size: large !important;
}
</style>
