export default () => {
  return {
    namespaced: true,
    state: {
      isAppLoading: false
    },
    getters:{
      isAppLoading: (state) => {
        return state.isAppLoading
      }
    },
    mutations:{
      appLoading(state, isLoading){
        state.isAppLoading = isLoading
      }
    }
  }
}
