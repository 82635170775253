export default ({ Vue, store }) => {
  return {
    path: "/vendors/fujicam",
    name: "vendor_fujicam",
    meta: {
      title: "Processing...",
    },
    beforeEnter: async (to, from, next) => {
      store.commit("appState/appLoading", true);
      let finalRedirect;
      const { payload, username, event_notify_url, dev_id} = to.query;
      const { data: res } = await Vue.$http.post("/fujicam/ad", {
        username,
        payload,
        event_notify_url,
        dev_id
      });

      if (!res.errors || !res.errors.length){
        //logged in
        if (res.data) {
          await store.dispatch("userContext/signin", res.data);
          finalRedirect = { name: "sso", query: res.data.payload};
        }
        //email not bound
        else{
          finalRedirect = {
            name: "bindEmail",
            query: {
              username,
              redirect: to.fullPath
            }
          }
        }
      }
      else{
        finalRedirect = {
          name: "notice",
          params: {
            title: "Error Occurred",
            type: "error",
            content: res.errors.join(";"),
            actions: ["home"]
          }
        };
      }

      if (typeof finalRedirect === "object") {
        next({ ...finalRedirect, replace: true });
        return store.commit("appState/appLoading", false);
      }

      window.location.href = finalRedirect;
      next();
    },
  };
};
