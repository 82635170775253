<template>
  <div>
    <v-container tag="header" class="mt-4 text-center">
      <component :is="this.$vuetify.icons.values.logoFull.component" style="height: 60px"></component>
    </v-container>
    <v-main>
      <v-layout align-center justify-center class="px-6">
        <v-flex xs12 sm10 md8 lg6 xl5>
          <router-view/>
        </v-flex>
      </v-layout>
    </v-main>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>

</style>
