import { render, staticRenderFns } from "./1col-centered.vue?vue&type=template&id=5aa7de09&scoped=true&"
import script from "./1col-centered.vue?vue&type=script&lang=js&"
export * from "./1col-centered.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa7de09",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VFlex,VLayout,VMain})
