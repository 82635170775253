import LocalStorage from "../../lib/localStorage";
const KEY_USER = "user";
const KEY_AUTH = "auth";
export default (Vue) => {
  return {
    namespaced: true,
    state: {
      user: null,
      auth: null,
    },
    getters: {
      //this is method-style accessor to prevent vuex cache
      //for reactivity we still need to return state instead of local storage
      user: (state, getters) => () => {
        state.user = LocalStorage.get(KEY_USER);
        state.auth = getters.auth();
        let user = state.user;
        if (getters.isLoggedIn()) {
          user = state.auth.user;
        }

        return user;
      },
      isLoggedIn: () => () => {
        const auth = LocalStorage.get(KEY_AUTH);
        return auth && auth.token && auth.token.length;
      },
      auth: () => () => {
        return LocalStorage.get(KEY_AUTH);
      },
    },
    mutations: {
      setUser(state, user) {
        state.user = user;
        LocalStorage.set(KEY_USER, user);
      },
      delUser(state) {
        state.user = null;
        LocalStorage.del(KEY_USER);
      },
      signin(state, { auth }) {
        state.auth = auth;
        LocalStorage.set(KEY_AUTH, auth);
        return auth;
      },
      signout(state) {
        state.auth = null;
        LocalStorage.del(KEY_AUTH);
      },
      setToken(state, token) {
        if (this.getters["userContext/isLoggedIn"]()) {
          const auth = this.getters["userContext/auth"]();
          auth.token = token;
          return this.commit("userContext/signin", { auth });
        }
      },
    },
    actions: {
      signin({ commit }, auth) {
        if (auth && auth.user && auth.token) {
          commit("delUser");
          if(Vue.prototype.$gtag){
            Vue.prototype.$gtag.event("login");
            Vue.prototype.$gtag.set({
              user_id: auth.user.id
            });
          }

          return commit("signin", { auth });
        }

        throw new Error("invalid user auth context");
      },
      async signout({ commit }) {
        const { data: signoutRes } = await Vue.$http.post("/account/signout");
        commit("delUser");
        commit("signout");

        return signoutRes;
      },
      async reloadUserAuth({ commit, getters }) {
        const { data: user } = await Vue.$http.post("/account/auth-detail");
        const auth = getters["auth"]();
        auth.user = user;
        return commit("signin", { auth });
      },
    },
  };
};
