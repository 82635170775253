<template>
  <v-flex xs12 sm10 md8 lg6 xl5>
    <v-skeleton-loader type="article, list-item-two-line, paragraph, actions" v-if="this.isPageLoading" class="mt-2">
    </v-skeleton-loader>

    <v-card v-else flat>
      <v-card-title class="text-uppercase">
        {{ this.$route.meta.title }}
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form :disabled="isSubmitting">
            <ValidationProvider v-slot="{ errors }" name="gender" :rules="rules.gender" :customMessages="valiMessages.gender">
              <v-select v-model="model.gender" :items="Object.entries($server_configs.constants.genders).map(([text, value]) => {return {text, value}})" label="Gender" :error-messages="errors" required hint="Please select your gender" clearable></v-select>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="first name" :rules="rules.firstName" :customMessages="valiMessages.firstName">
              <v-text-field
                v-model="model.firstName"
                label="First Name"
                required
                :error-messages="errors"
                hint="Please fill in your first name"
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="last name" :rules="rules.lastName" :customMessages="valiMessages.lastName">
              <v-text-field
                v-model="model.lastName"
                label="Last Name"
                required
                :error-messages="errors"
                hint="Please fill in your last name"
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="middle name" :rules="rules.middleName" :customMessages="valiMessages.middleName">
              <v-text-field
                v-model="model.middleName"
                label="Middle Name"
                required
                :error-messages="errors"
                hint="Please fill in your middle name"
              ></v-text-field>
            </ValidationProvider>

            <v-menu
              ref="menu"
              v-model="showDOBPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" name="date of birth" :rules="rules.DOB" :customMessages="valiMessages.DOB">
                  <v-text-field
                    v-model="model.DOB"
                    label="Birthday date"
                    readonly
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend>
                      <i class="v-icon theme--light">
                        <font-awesome-icon :icon="['fas', 'calendar-alt']"></font-awesome-icon>
                      </i>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                ref="picker"
                v-model="model.DOB"
                :max="new Date().toISOString().substr(0, 10)"
                :min="minDOB"
                @change="saveDOB"
              >
                <v-spacer></v-spacer>
                <v-btn text @click="saveDOB">
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" outlined :loading="isSubmitting"> Save Profile </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>
<script>

export default {
  name: "profile",
  data: () => {
    let minDOB = new Date()
    minDOB.setDate(new Date().getDate() - 365 * 100)
    minDOB = minDOB.toISOString().substr(0, 10)
    return {
      isPageLoading: true,
      isSubmitting: false,
      showDOBPicker: false,
      minDOB,
      model: {
        gender: null,
        firstName: null,
        lastName: null,
        middleName: null,
        DOB: null

      },
      rules: null,
      valiMessages: null
    }
  },
  watch: {
    showDOBPicker (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods:{
    saveDOB (date) {
      this.$refs.menu.save(date)
    },
    async submit(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isSubmitting = true
          const { data: res } = await this.$http.post(
            "/account/profile/createOrUpdate",
            this.model
          );

          if (!res.errors || !res.errors.length){
            this.$alert({
              contents: ["Your profile has been saved successfully."]
            })
            await this.load()
            await this.$store.dispatch("userContext/reloadUserAuth")
          }
          this.isSubmitting = false
        }
      })
    },
    async load(){
      this.isPageLoading = true
      const [{ data: rules }, { data: profile }] = await Promise.all([
        this.$http.post("/account/validationRules", {
          vType: "vee",
        }),
        this.$http.post("/account/profile")
      ])
      this.rules = Object.assign({}, rules)
      Object.assign(this.model, profile)
      this.valiMessages = this.$utils.normalizeRuleMessages(this.rules)
      this.isPageLoading = false
    }
  },
  async mounted() {
    await this.load()
  }
};
</script>

<style scoped>

</style>
