import Vuetify from "vuetify/lib/framework";
import IconsLib from "@/plugins/iconsLib";

export default (Vue) => {
  Vue.use(Vuetify);
  return new Vuetify({
    defaultAssets: false,
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        light: {
          primary: "#f54f5c",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
        },
      },
    },
    icons: {
      iconfont: "faSvg",
      values: IconsLib(Vue)
    },
  })
};
