import { required, email, min, confirmed, oneOf } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
export default (Vue) => {
  extend("required", required)
  extend("email", email)
  extend("min", min)
  extend("confirmed", confirmed)
  extend("oneOf", oneOf)
  Vue.component("ValidationProvider", ValidationProvider)
  Vue.component("ValidationObserver", ValidationObserver)
  setInteractionMode("eager");
  Object.defineProperties(Vue.prototype, {
    $V: {
      get: function get() {
        return { extend, setInteractionMode };
      },
    },
  });
}
