import Alert from "../components/alert"
export default (Vue) => {
  const typedAttrs = {
    success: {
      color: "light-green"
    },
    info: {
      color: "blue-grey"
    },
    error: {
      color: "red accent-3"
    }
  };
  const default_type = "success";

  Vue.prototype._alertAttrs = Object.assign({}, {
      app: true,
      top: true,
      timeout: 3000,
      visible: false,
      contents: []
    },
    typedAttrs[default_type]
  );
  Vue.prototype.$alert = ({ contents, type = default_type, attrs }) => {
    Object.assign(
      Vue.prototype._alertAttrs,
      {
        ...typedAttrs[type],
        contents
      },
      attrs,
      { visible: true }
    );
  };

  Vue.component("alert", Alert)
};
