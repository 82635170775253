import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faEnvelopeOpenText,
  faInfoCircle,
  faUserCircle,
  faUnlockAlt,
  faSignOutAlt,
  faBars,
  faArrowCircleLeft,
  faCaretDown,
  faTimesCircle,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faExclamation,
  faExclamationTriangle,
  faCheckCircle,
  faGifts,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

const icons = [
  faSquare,
  faCheckSquare,
  faEnvelopeOpenText,
  faInfoCircle,
  faUserCircle,
  faUnlockAlt,
  faSignOutAlt,
  faBars,
  faArrowCircleLeft,
  faCaretDown,
  faTimesCircle,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faExclamation,
  faExclamationTriangle,
  faCheckCircle,
  faGifts,
  faChevronDown,
  faChevronUp
];
library.add(...icons);

export default (Vue) => {
  const {shopStore} = Vue.prototype.$server_configs

  return {
    logoSingle: {
      component: shopStore ? () => import(`@/assets/icons/logo-single-${shopStore.id}.svg`) : null
    },
    logoFull: {
      component: shopStore ? () => import(`@/assets/icons/logo-full-${shopStore.id}.svg`) : null
    }
  }
};
