<template>
  <v-flex>
    <v-card flat>
      <v-card-title class="justify-center text-uppercase">
        {{this.$shop("name")}} Terms of Service
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="font-italic">Effective: January 30, 2021</p>

        <div>
          <p>
            {{this.$shop("domain")}} and its affiliates (collectively, "<span class="text-capitalize">{{this.$shop("name")}}</span>", "we" or "us") provide:
          </p>

          <ul>
            <li>
              the websites located at {{this.$shop("url")}}, and all associated web
              pages, websites and corresponding social media pages of <span class="text-capitalize">{{this.$shop("name")}}</span>
              (collectively, the "Site"), your use of which is governed by
              these Terms of Service (these "Terms");
            </li>

            <li>
              mobile application software that may be downloaded to your
              smartphone or tablet to access services (the "<span class="text-capitalize">{{this.$shop("name")}}</span> App"), your
              use of which is governed by these Terms and the End User License
              Agreement for iOS or the End User License Agreement for Android,
              as applicable;
            </li>

            <li>
              <span class="text-capitalize">{{this.$shop("name")}}</span> hardware products (the "Products") and services
              accessible via the Site and the <span class="text-capitalize">{{this.$shop("name")}}</span> App (such services,
              together with the Site and the <span class="text-capitalize">{{this.$shop("name")}}</span> App, collectively the
              "Services"). Your use of the Products and Services are governed
              by these Terms and any applicable additional guidelines, terms
              or rules (the "Supplemental Terms"), including those listed
              below. If there is a conflict between these Terms and any
              Supplemental Terms, the Supplemental Terms will control for that
              conflict.
            </li>
          </ul>

          <p>
            In addition to these Terms and the applicable Supplemental Terms,
            your purchase of any Product is governed by the Terms of Sale
            ("Terms of Sale") below and the limited warranty set forth below.
          </p>

          <p class="font-weight-bold">
            BY CREATING AN ACCOUNT OR ACCESSING, PURCHASING, OR USING ANY OF
            OUR PRODUCTS OR SERVICES, YOU ACCEPT AND AGREE TO BE BOUND BY
            THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, INCLUDING THE
            MANDATORY ARBITRATION PROVISION AND CLASS ACTION WAIVER IN SECTION
            12, DO NOT USE OUR PRODUCTS OR SERVICES.
          </p>

          <p>
            We may make changes to these Terms from time to time. If we make
            changes, we will provide you with notice of such changes, such as
            by sending an email, providing a notice through our Services or
            updating the date at the top of these Terms. Unless we say
            otherwise in our notice, the amended Terms will be effective
            immediately, and your continued use of our Products or Services
            after we provide such notice will confirm your acceptance of the
            changes. If you do not agree to the amended Terms, you must stop
            using our Services.
          </p>

          <p>
            If you have any questions about these Terms or our Services,
            please contact us:
            <a :href="`mailto:${this.$shop('config.contacts.customer_service')}`">{{
                this.$shop('config.contacts.customer_service')
              }}</a>
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.privacy">
          PRIVACY
        </v-card-subtitle>

        <p>
          For information about how we collect, use, share and otherwise
          process information, please see our
          <router-link :to="{ name: 'privacy' }" target="_blank"
          >Privacy Statement</router-link
          >
          for {{this.$shop("domain")}} and Privacy Statement for <span class="text-capitalize">{{this.$shop("name")}}</span> Products & App.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.use">
          USE OF <span class="text-uppercase">{{this.$shop("name")}}</span> PRODUCTS AND SERVICES
        </v-card-subtitle>

        <div>
          <p class="font-weight-bold">A. Eligibility</p>
          <p>
            You must be at least 16 years of age to access, purchase, or use
            any of our Products or Services. If you are under 16 years of age,
            do not use, purchase or sign up for any Products or Services. If
            you are under the age of 18 (or the age of legal majority where
            you live), you may use our Products or Services only under the
            supervision of a parent or legal guardian who has reviewed and
            agreed to these Terms. Any parent or legal guardian of a user
            under the age of 18 (or the age of legal majority) who has
            reviewed these Terms as required by the previous sentence, hereby
            agrees to be fully responsible for the acts or omissions of such
            user in relation to the use of our Products or Services. If you
            use our Products or Services on behalf of another person or
            entity,
          </p>
          <ol class="list-roman">
            <li>
              all references to "you" throughout these Terms will include that
              person or entity;
            </li>
            <li>
              you represent that you are authorized to accept these Terms on
              that person's or entity's behalf, and
            </li>
            <li>
              in the event you, the person, or the entity violate these Terms,
              both you and the other person or entity agree to be jointly and
              severally responsible and liable for any such violation.
            </li>
          </ol>
          <p>
            Accessing the Site from territories where its contents are illegal
            or unlawful is prohibited. If you choose to access this Site from
            locations outside the United States and Canada, you do so at your
            own risk.
          </p>
          <p>
            You may not create more than one <span class="text-capitalize">{{this.$shop("name")}}</span> account. You may not use,
            purchase or sign up for any of the Products or Services if you
            were previously prohibited by <span class="text-capitalize">{{this.$shop("name")}}</span> from using the Products or
            Services.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">
            2. USE OF <span class="text-uppercase">{{this.$shop("name")}}</span> PRODUCTS AND SERVICES
          </p>
          <p>
            To use certain Services and Products, you must register or sign up
            for a user account with <span class="text-capitalize">{{this.$shop("name")}}</span> ("Account") and provide certain
            information about yourself, as prompted by the applicable sign up
            screen or registration form. You represent and warrant that:
          </p>
          <ol class="list-roman">
            <li>
              all required registration and Account information that you
              submit is truthful, current and accurate;
            </li>
            <li>you will maintain the accuracy of such information;</li>
            <li>
              you are not located in a country that is subject to a U.S.
              Government embargo or that has been designated by the U.S.
              Government as a "terrorist supporting" country;
            </li>
            <li>
              you are not listed on any U.S. Government list of prohibited or
              restricted parties; and
            </li>
            <li>
              your use of the Products or Services will not violate any United
              States or other applicable law or regulation.
            </li>
          </ol>
          <p>
            You must maintain the security of your account and promptly notify
            us if you discover or suspect that someone has accessed your
            account without your permission. If you permit others to use your
            account credentials, you are responsible for the activities of
            such users that occur in connection with your account. We reserve
            the right to reclaim usernames, including on behalf of businesses
            or individuals that hold legal claim, including trademark rights,
            in those usernames.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">
            C. Suspension or Termination of Account
          </p>
          <p>
            <span class="text-capitalize">{{this.$shop("name")}}</span> may suspend or terminate your Account and/or access to the
            Services at any time upon becoming aware of any violation or
            threatened violation of the Terms, any security or other threat to
            our systems, technology, business or to other people, or other
            matters warranting such suspension or termination. We are not
            responsible for any loss or harm related to your inability to
            access or use our Services.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">D. Access and Use</p>
          <ul>
            <li>
              You may access and use the Services as permitted by, and subject
              to, these Terms and any other applicable agreements between you
              and <span class="text-capitalize">{{this.$shop("name")}}</span>. You are solely responsible for your conduct while
              using our Products or Services and you will not violate any
              applicable law, contract, intellectual property right or other
              third-party right or commit a tort while using our Products or
              Services. Without limiting the generality of the foregoing, you
              will not:
            </li>
            <li>
              use or attempt to use another user's Account without
              authorization from that user and <span class="text-capitalize">{{this.$shop("name")}}</span>;
            </li>
            <li>
              permit any third party to access the Services except as
              permitted herein;
            </li>
            <li>
              sell or resell our Products or Services, or commercially use our
              Products or Services;
            </li>
            <li>
              copy, reproduce, distribute, publicly perform or publicly
              display all or portions of our Services, except as expressly
              permitted by us;
            </li>
            <li>
              modify our Products or Services, remove any proprietary rights
              notices or markings, or otherwise make any derivative works
              based upon our Products or Services;
            </li>
            <li>
              use our Products or Services other than for their intended
              purpose or in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying our
              Products or Services or that could damage, disable, overburden
              or impair the functioning of our Products or Services in any
              manner;
            </li>
            <li>
              reverse engineer any aspect of our Products or Services or do
              anything that might discover source code or bypass or circumvent
              measures employed to prevent or limit access to any part of our
              Products or Services;
            </li>
            <li>
              copy, frame or mirror any part or content of the Services, other
              than copying or framing in your own intranet or otherwise for
              your own internal business purposes;
            </li>
            <li>
              use any data mining, robots or similar data gathering or
              extraction methods designed to scrape or extract data from our
              Products or Services;
            </li>
            <li>
              develop or use any applications, except those applications
              provided by <span class="text-capitalize">{{this.$shop("name")}}</span>, that interact with our Products or Services
              without our prior written consent;
            </li>
            <li>
              use our Products or Services for any illegal or unauthorized
              purpose, or engage in, encourage or promote any activity that
              violates these Terms;
            </li>
            <li>
              copy any feature, function or graphic of the Products or
              Services;
            </li>
            <li>
              access our Products or Services in order to build a competitive
              product or service, or copy any feature, function or graphic of
              the Products or Services.
            </li>
          </ul>
        </div>

        <div>
          <p class="font-weight-bold">E. Automatic Software Updates</p>
          <p>
            <span class="text-capitalize">{{this.$shop("name")}}</span> may, in its sole discretion from time to time, provide
            updates, patches, fixes and other modifications to the Products or
            Services (collectively "Updates"), but has no obligation to do so.
            Any Update will be subject to these Terms. Updates may be
            automatically installed without providing any additional notice or
            obtaining any additional consent from you, and you hereby consent
            to these automatic Updates. If you do not want such Updates, your
            remedy is to terminate your Account with us and stop using the
            Products and Services. You may be required to install Updates to
            use the <span class="text-capitalize">{{this.$shop("name")}}</span> App or other parts of the Products or Services, and
            you will promptly install any Updates that <span class="text-capitalize">{{this.$shop("name")}}</span> makes available
            to you.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">F. System Requirements</p>
          <p>
            The Products and Services will not be fully accessible without:
          </p>
          <ul>
            <li>
              a working Wi-Fi network that is positioned to communicate
              reliably with the Products;
            </li>
            <li>an Account;</li>
            <li>
              an enabled and supported wireless device with the <span class="text-capitalize">{{this.$shop("name")}}</span> App
              installed, such as a phone or tablet;
            </li>
            <li>
              always-on broadband Internet access with bandwidth sufficient to
              support the Products and Services;
            </li>
            <li>
              other system elements that may be specified by <span class="text-capitalize">{{this.$shop("name")}}</span>, as and
              when applicable.
            </li>
          </ul>
          <p>
            It is your responsibility to ensure that you have all required
            system elements and that they are compatible and properly
            configured. The Products and Services may not work as described
            when such requirements have not been met. In the event of any
            service interruptions to your home's Wi-Fi network or internet
            connection, or to your mobile, telephone and other network
            connections with respect to the <span class="text-capitalize">{{this.$shop("name")}}</span> App, the Products or
            Services may be unreliable or unavailable for the duration of such
            interruption. Because you are solely responsible for ensuring
            proper connections for transmission of data with respect to the
            Products and Services, we are not responsible for any such
            interruptions and any resulting failures of the Products or
            Services.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">G. Export and Sanctions Compliance</p>
          <p>
            You may not export or re-export the Products, Services or related
            technology, or any content contained therein, except as authorized
            by export control and sanctions laws of the United States and any
            other government having jurisdiction. In particular, but without
            limitation, the Products and Services, and the technology and the
            content contained therein, may not be exported or re-exported to
            (a) any U.S. sanctioned or embargoed countries or regions; or (b)
            any person or entity on the U.S. Treasury Department's list of
            Specially Designated Nationals, the U.S. Department of Commerce
            Denied Person's List or Entity List, or other U.S. sanctions or
            export control lists.
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.user">
          USER CONTENT
        </v-card-subtitle>
        <div>
          <p class="font-weight-bold">A. Content Submission and Use</p>

          <p>
            Our Services may allow you and other users to create, post, use,
            share and store content, including message board posts, comments,
            videos, messages, photos or other preferences you express or
            materials you share with us or other users when using our Services
            (collectively, "User Content"). Except for the license you grant
            below, you retain all rights in and to your User Content, as
            between you and <span class="text-capitalize">{{this.$shop("name")}}</span>.
          </p>

          <p>
            You grant <span class="text-capitalize">{{this.$shop("name")}}</span> and affiliates a perpetual, irrevocable,
            nonexclusive, royalty-free, worldwide, fully paid, and
            sublicensable license to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, and distribute your User
            Content (including, for avoidance of doubt, any name, username or
            likeness provided in connection with your User Content or
            associated with your account) in all media formats and channels
            now known or later developed without compensation to you.
            Depending on your account settings and the options you select,
            when you post or otherwise share User Content on or through our
            Services, you understand that some or all of your User Content
            (including your name, username or, profile photo and other account
            information) may be visible to others.
          </p>

          <p>User Content may not:</p>

          <ul>
            <li>
              be unlawful, libelous, defamatory, obscene, pornographic,
              indecent, lewd, suggestive, harassing, threatening, invasive of
              privacy or publicity rights, abusive, inflammatory or
              fraudulent;
            </li>
            <li>
              constitute, encourage or provide instructions for a criminal
              offense, violate the rights of any party or otherwise create
              liability or violate any local, state, national or international
              law;
            </li>
            <li>
              contain or depict any statement, remark or claim that does not
              reflect your actual view or experience;
            </li>
            <li>
              impersonate, or misrepresent your affiliation with, any person
              or entity;
            </li>
            <li>
              contain any unsolicited promotion, political campaigning,
              advertising or solicitation;
            </li>
            <li>
              contain any private or personal information of a third party
              without such third party's consent;
            </li>
            <li>
              in our sole judgment, be objectionable, restrict or inhibit any
              other person from using or enjoying our Services, or expose us
              or others to any harm or liability of any type.
            </li>
          </ul>
          <p>
            We ay remove any User Content at any time if we determine that it
            violates any of these Terms. You represent and warrant that:
          </p>
          <ol class="list-alpha">
            <li>
              you have all necessary rights in the User Content to make it
              available through the Services and grant the license rights in
              these Terms
            </li>
            <li>
              our use of User Content as permitted by these Terms will not
              violate any rights of or cause injury to any person or entity.
            </li>
          </ol>
          <p>
            Although we have no obligation to screen, edit or monitor User
            Content, we may delete or remove User Content at any time and for
            any reason with or without notice.
          </p>
        </div>

        <div>
          <p class="font-weight-bold">B. Prohibited Content</p>
          <p>
            In connection with your use of any Product or Services, you will
            not:
          </p>
          <ul>
            <li>
              engage in any harassing, threatening, intimidating, predatory or
              stalking conduct;
            </li>
            <li>
              impersonate or post on behalf of any person or entity without
              their authorization or otherwise misrepresent your affiliation
              with a person or entity;
            </li>
            <li>
              use our Products or Services other than for their intended
              purpose and in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying our
              Services or that could damage, disable, overburden or impair the
              functioning of our Services in any manner;
            </li>
            <li>
              send, distribute or post spam, unsolicited or bulk commercial
              electronic communications, chain letters, or pyramid schemes; or
            </li>
            <li>
              use our Products or Services for any illegal or unauthorized
              purpose, or engage in, encourage or promote any activity that
              violates these Terms.
            </li>
          </ul>
          <p>
            Enforcement of this Section is solely at <span class="text-capitalize">{{this.$shop("name")}}</span>'s discretion, and
            failure to enforce this Section in some instances does not
            constitute a waiver of our right to enforce it in other instances.
            In addition, this Section does not create any private right of
            action on the part of any third-party or any reasonable
            expectation that the Services will not contain any content that is
            prohibited by such rules.
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.digital">
          DIGITAL MILLENNIUM COPYRIGHT ACT
        </v-card-subtitle>
        <p class="font-weight-bold">A. Notice of Infringing Content</p>
        <p>
          In accordance with the Digital Millennium Copyright Act and other
          applicable law, we have adopted a policy of terminating, in
          appropriate circumstances, the accounts of users who repeatedly
          infringe the intellectual property rights of others. If you believe
          that anything on our Services infringes any copyright that you own
          or control, you may notify <span class="text-capitalize">{{this.$shop("name")}}</span> as follows:
        </p>
        <p>
          <a :href="`mailto:${this.$shop('config.contacts.customer_service')}`">{{
              this.$shop('config.contacts.customer_service')
            }}</a>
        </p>
        <p>
          Please see 17 U.S.C. § 512(c)(3) for the requirements of a proper
          notification. Also, please note that if you knowingly misrepresent
          that any activity or material on our Services is infringing, you may
          be liable to <span class="text-capitalize">{{this.$shop("name")}}</span> for certain costs and damages.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.party">
          THIRD-PARTY PRODUCTS, SERVICES AND CONTENT
        </v-card-subtitle>
        <p class="font-weight-bold">A. General</p>
        <p>
          <span class="text-capitalize">{{this.$shop("name")}}</span> Products and Services rely on or inter-operate with
          third-party products and services, including, without limitation,
          data storage services, communications technologies, IoT platforms,
          third party app stores, and internet and mobile operators
          (collectively, "Third-Party Materials"). These Third-party Materials
          are beyond <span class="text-capitalize">{{this.$shop("name")}}</span>'s control, but their operation may impact, or be
          impacted by, the use and reliability of the <span class="text-capitalize">{{this.$shop("name")}}</span> Services and
          Products. You acknowledge that:
        </p>
        <ul>
          <li>
            the use and availability of the Products and Services is dependent
            on third-party product vendors and service providers;
          </li>
          <li>
            these Third-Party Materials may not operate in a reliable manner
            100% of the time and they may impact on the way that the <span class="text-capitalize">{{this.$shop("name")}}</span>
            Services operate;
          </li>
          <li>
            except as otherwise required by law, <span class="text-capitalize">{{this.$shop("name")}}</span> is not responsible for
            damages and losses due to the operation of these Third-Party
            Materials.
          </li>
        </ul>
        <p>
          Additionally, certain items of independent, third-party code may be
          utilized in connection with the Products or Services that may be
          subject to open-source licenses ("Open-source Software"). The
          Open-source Software is licensed under the terms of the license that
          accompanies such Open-source Software. Nothing in the Terms limits
          your rights under, or grants you rights that supersede, the terms
          and conditions of any applicable license for such Open-source
          Software.Additionally, certain items of independent, third-party
          code may be utilized in connection with the Products or Services
          that may be subject to open-source licenses ("Open-source
          Software"). The Open-source Software is licensed under the terms of
          the license that accompanies such Open-source Software. Nothing in
          the Terms limits your rights under, or grants you rights that
          supersede, the terms and conditions of any applicable license for
          such Open-source Software.
        </p>
        <p>
          We may provide information about third-party products, services,
          activities or events, or we may allow third parties to make their
          content, data, information and other materials available on or
          through the Services (collectively, "Third-Party Content"). We
          provide Third-Party Content as a service to those who may be
          interested in such content. Your dealings or correspondence with
          third parties and your use of or interaction with any Third-Party
          Content are solely between you and the third party. <span class="text-capitalize">{{this.$shop("name")}}</span> does not
          control or endorse, and makes no representations or warranties
          regarding, any Third-Party Content, and your access to and use of
          such Third-Party Content is at your own risk.
        </p>
        <p>
          Some Third-Party Materials and Third-Party Content have their own
          separate terms of use or terms of service as well as their own
          privacy policies. If applicable, you are subject to, and will comply
          with, such terms and policies. To the extent that such other terms
          and policies are less restrictive than a provision in these Terms,
          the provision in these Terms will supersede.
        </p>
        <p>
          We do not warrant or endorse, and will have no responsibility with
          respect to:
        </p>
        <ol class="list-roman">
          <li>
            products or services that you have been offered or that you obtain
            from a third party that relate to the Products or Services, even
            if accessed through a hyperlink or other method using the
            Services,
          </li>
          <li>any Third-Party Materials or Third-Party Content, or</li>
          <li>
            any data or other information you may provide to any third party.
            You are responsible for obtaining and maintaining any computer
            hardware, equipment, network services and connectivity,
            telecommunications services and other products and services
            necessary to access and use the Products and Services.
          </li>
        </ol>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.ownership">
          OWNERSHIP AND INTELLECTUAL PROPERTY
        </v-card-subtitle>

        <p class="font-weight-bold">A. Ownership</p>
        <p>
          The Services, including the text, graphics, images, photographs,
          videos, illustrations and other content contained therein, and all
          intellectual property therein and in the Products, are owned by
          <span class="text-capitalize">{{this.$shop("name")}}</span> or our licensors and are protected under both United States
          and foreign laws. We reserve all right, title and interest in and to
          the Products or Services, including all related intellectual
          property rights. No rights are granted to you hereunder other than
          as expressly set forth herein.
        </p>

        <p class="font-weight-bold">B. <span class="text-capitalize">{{this.$shop("name")}}</span> Trademarks</p>
        <p>
          The "<span class="text-capitalize">{{this.$shop("name")}}</span>", "<span class="text-capitalize">{{this.$shop("name")}}</span> CAM",our logos, our other Product and Service
          names, our slogans and the look and feel of the Services are
          trademarks of <span class="text-capitalize">{{this.$shop("name")}}</span> and may not be copied, imitated or used, in
          whole or in part, without our prior written permission. All other
          trademarks, registered trademarks, Product names and company names
          or logos mentioned on the Services are the property of their
          respective owners. Reference to any Products, Services, processes or
          other information by trade name, trademark, manufacturer, supplier
          or otherwise does not constitute or imply endorsement, sponsorship
          or recommendation by us.
        </p>

        <p class="font-weight-bold">C. Feedback</p>
        <p>
          You may choose to or <span class="text-capitalize">{{this.$shop("name")}}</span> may invite you to submit comments,
          suggestions or ideas about our Products or Services, including how
          to improve the Products or Services ("Feedback"). By submitting or
          providing any Feedback, you agree that your submissions are
          voluntary, gratuitous and without restriction, and will not place
          <span class="text-capitalize">{{this.$shop("name")}}</span> under any obligations to you or any other party. <span class="text-capitalize">{{this.$shop("name")}}</span> may
          use, copy, modify, publish or redistribute the Feedback and its
          contents for any purpose and in any way without any compensation to
          you. <span class="text-capitalize">{{this.$shop("name")}}</span> does not waive any rights to use similar or related
          ideas previously known to <span class="text-capitalize">{{this.$shop("name")}}</span>, developed by its employees or
          obtained from other sources.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.sale">
          <span class="text-capitalize">{{this.$shop("name")}}</span> TERMS OF SALE
        </v-card-subtitle>
        <p>
          The following Terms of Sale apply to the purchase of Products and
          Services through our Site.
        </p>
        <p>
          By purchasing a Product or Service, you accept and agree to be bound
          by these Terms of Sale. If you do not agree to these Terms of Sale,
          do not purchase Products or Services from the Site.
        </p>

        <p class="font-weight-bold">A. Order Eligibility</p>
        <p>
          To complete your purchase, you must have a valid billing and
          shipping address within a country that can be selected for the
          billing and shipping addresses as part of the checkout process on
          the Site (the "Territory"). We make no promise that Products or
          Services available on the Site are appropriate or available for use
          in locations outside the Territory.
        </p>

        <p class="font-weight-bold">
          B. Product Availability, Display, and Specifications
        </p>
        <p>
          Details of the Products and Services available for purchase are set
          out on the Site. All features, content, specifications, Products,
          and prices of Products and Services described or depicted on the
          Site are subject to change at any time without notice. We attempt to
          ensure that information on the Site is complete, accurate, and
          current. Despite our efforts, the information on the Site may
          occasionally be inaccurate, incomplete, or out of date. We make no
          representation as to the completeness, availability, accuracy or
          currency of any information on the Site. For example, Products or
          Services included on the Site may be unavailable, may have different
          attributes than those listed, or may actually carry a different
          price than that stated on the Site.
        </p>
        <p>
          We make reasonable efforts to publish information and display
          Product images accurately, but we cannot guarantee that the image
          you see on your monitor or mobile device will exactly match the
          Product. Certain weights, measures and similar descriptions are
          approximate and are provided for convenience purposes only.
          Packaging may vary from that shown.
        </p>

        <p class="font-weight-bold">
          C. Purchase Restrictions and Quantity Limits
        </p>
        <p>
          You may only purchase Products and Services for personal use by
          either yourself or your intended recipient of the Products. The
          Products and Services are not authorized for resale. We may place a
          limit on the quantities that may be purchased per order, per
          account, per credit card, per person, or per household. We reserve
          the right, without prior notice, to refuse service to any customer
          or reject any order at any time and refund any money you have paid
          for such order.
        </p>

        <p class="font-weight-bold">D. Order Process; Errors</p>
        <p>
          You will have the opportunity to review and confirm your order,
          including delivery address, payment method, and other details of
          your order. We will display or send a notice when we accept your
          order and our acceptance will be complete at the time we display or
          send the notice. We will arrange for shipment of the Products to
          you. You will pay all shipping and handling charges specified during
          the ordering process. Shipping and handling charges are
          reimbursement for the costs we incur in the processing, handling,
          packing, shipping and delivery of your order. Title and risk of loss
          in any goods you purchase pass to you when the shipping carrier
          receives the goods from <span class="text-capitalize">{{this.$shop("name")}}</span>. Shipping and delivery dates are
          estimates only and cannot be guaranteed. We are not liable for any
          delays in shipments.
        </p>
        <p>
          We reserve the right to refuse or cancel any order prior to
          delivery. For example, if there are errors on the Site or made in
          connection with your order, inaccuracies in Product or pricing
          information or Product availability, we reserve the right to correct
          the error and charge you the correct price or cancel your order. We
          also may require verification of information prior to the acceptance
          and/or shipment of any order. We will contact you if any portion of
          your order is cancelled or if additional information is required to
          accept your order. Occasionally, the manufacture or distribution of
          a certain Product or Service may be delayed. In such event, we will
          make reasonable efforts to notify you of the delay and keep you
          informed of the revised delivery schedule.
        </p>

        <p class="font-weight-bold">E. Price</p>
        <p>
          Prices shown on the Site exclude all taxes or charges for shipping
          and handling. Taxes and shipping and handling costs will be added to
          the amount of your purchase and itemized on the check-out page. You
          will have an opportunity to review taxes and delivery costs before
          you confirm your purchase. Each item in your shopping cart is shown
          at the current price. All prices on the Site are subject to change
          at any time without notice. We do not collect taxes on merchandise
          shipped to a jurisdiction in which we do not have substantial nexus,
          in which case you will be liable to the appropriate taxing authority
          for any sales taxes relating to your purchase from the Site.
        </p>

        <p class="font-weight-bold">F. Service and Other Fees</p>
        <p>
          Some Services may be provided for a fee, either now or in the
          future. When you select or use Services subject to a fee, you will
          pay the applicable fees in connection with such Service(s) in
          accordance with the amounts and payment terms (including due dates)
          specified in the applicable Supplemental Terms or on the Site and/or
          the <span class="text-capitalize">{{this.$shop("name")}}</span> App. If no timing or due date for a given Service fee is
          specified, such fee will be due upon selection or sign up for the
          applicable Service. Some paid Services may be offered on a
          subscription basis with fees charged to you upon periodic renewals
          of such Service absent your termination of such Service prior to the
          renewal fee becoming due. Unless otherwise noted, all fee payment
          obligations for Services are non-cancelable and all Service fees
          paid are non-refundable and non-creditable. <span class="text-capitalize">{{this.$shop("name")}}</span> may change any or
          all of its Service fees (or impose new fees) upon notification to
          you of such changed or new fees (except to the extent the applicable
          Supplemental Terms expressly state that fee rates are locked in for
          some period of time).
        </p>

        <p class="font-weight-bold">G. Payment</p>
        <p>
          The Site gives you different payment options. We reserve the right
          to change our payment options at any time and for any reason.
          Payment must be received by us before our acceptance of an order.
          When you provide credit card information or other information
          necessary to facilitate payment to us or our vendors, you represent
          to us that
        </p>
        <ol class="list-roman">
          <li>
            you are the authorized user of the credit card that is used to pay
            for the Products and Services,
          </li>
          <li>
            the credit card information you supply to us is true, correct and
            complete,
          </li>
          <li>
            charges incurred by you will be honored by your credit card
            company, and
          </li>
          <li>
            you will pay charges incurred by you at the posted prices,
            including shipping and handling charges and all applicable taxes,
            if any, regardless of the amount quoted on the Site at the time of
            your order.
          </li>
        </ol>
        <p>
          In the event legal action is necessary to collect on balances due,
          you will reimburse us and our vendors or agents for all expenses
          incurred to recover sums due, including attorneys' fees and other
          legal expenses. Credits or refunds will be made to the same method
          of payment and account used to place the order.
        </p>

        <p class="font-weight-bold">H. Returns</p>
        <p class="text-uppercase">
          OUR RETURN POLICY IS IN ADDITION TO ANY STATUTORY RIGHTS YOU MAY
          HAVE UNDER LAW. WE WILL ACCEPT ANY ITEMS RETURNED IN ACCORDANCE WITH
          OUR RETURN POLICY DESCRIBED ON OUR RETURN POLICY AND LIMITED
          WARRANTY TERMS. WE MAY DENY ANY RETURN IF IT FAILS TO MEET OUR
          RETURN CRITERIA DESCRIBED IN OUR RETURN POLICY. FOR ADDITIONAL
          INFORMATION ON OUR RETURN PROCESS AND POLICY, PLEASE VISIT OUR
          RETURN POLICY AND LIMITED WARRANTY TERMS.
        </p>

        <p class="font-weight-bold">I. Subscriptions</p>
        <p>
          Certain Products and Services may require you to enroll in a
          recurring subscription before they can be used. Recurring
          subscriptions may be made available on either a monthly or a yearly
          basis ("Subscription Periods") for the recurring fee indicated at
          the time you enroll in the subscription ("Fee"). You agree that your
          subscription is continuous until you cancel it and that you will be
          charged the Fee both
        </p>
        <ol class="list-alpha">
          <li>at the beginning of the Subscription Period and</li>
          <li>
            at the beginning of each subsequent Subscription Period until you
            cancel.
          </li>
        </ol>
        <p class="text-uppercase">
          YOU HEREBY AUTHORIZE <span class="text-capitalize">{{this.$shop("name")}}</span> TO AUTOMATICALLY CHARGE THE FEE TO YOUR
          CREDIT CARD, DEBIT YOUR BANK ACCOUNT, OR OTHERWISE BILL OR CHARGE
          YOU IN ACCORDANCE WITH ANY PAYMENT METHOD YOU HAVE SELECTED, AT THE
          BEGINNING OF EACH SUBSCRIPTION PERIOD UNTIL YOU CANCEL YOUR
          SUBSCRIPTION. YOU MUST CANCEL YOUR SUBSCRIPTION AT LEAST TWENTY-FOUR
          (24) HOURS BEFORE THE END OF YOUR CURRENT SUBSCRIPTION PERIOD IN
          ORDER TO AVOID BEING CHARGED FOR THE NEXT SUBSCRIPTION PERIOD.
        </p>

        <p>
          If you do not cancel at least twenty-four (24) hours before the end
          of the current Subscription Period, you will be charged for another
          Subscription Period. For example, if your monthly subscription
          begins on January 1, you must cancel at least twenty-four (24) hours
          before February 1, which means you must cancel on January 30 to
          avoid being charged for the Subscription Period beginning February
          1.
        </p>

        <p>
          If the subscription Fee changes, <span class="text-capitalize">{{this.$shop("name")}}</span> will notify you at least 30
          days in advance of the next Subscription Period. If you do not want
          to pay the new Fee, you must cancel your subscription at least
          twenty-four (24) hours before the end of the current Subscription
          Period.
        </p>

        <p>
          If you used a promo code, coupon code, or other discount to sign up
          for a subscription, your subscription will automatically renew at
          the end of the coupon period. <span class="text-capitalize">{{this.$shop("name")}}</span> will automatically charge your
          payment method on file, and you will be responsible for payment of
          the full Fee due.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.warranty">
          LIMITED WARRANTY AND DISCLAIMERS
        </v-card-subtitle>
        <p class="font-weight-bold">A. Limited Warranty</p>
        <p>
          <span class="text-capitalize">{{this.$shop("name")}}</span> offers a limited warranty for certain Products in accordance
          with the terms of its published limited warranty and returns policy
          (the "Limited Warranty") posted on our Return Policy and Limited
          Warranty Terms. All warranty claims are subject to the terms of this
          Limited Warranty.
        </p>

        <p class="font-weight-bold">
          B. Intended Use of <span class="text-capitalize">{{this.$shop("name")}}</span> Products and Services
        </p>
        <p>
          Our Products and Services are intended to be accessed and used for
          non-critical, non-commercial, home-based, personal uses and not for
          safety, security or time-sensitive purposes. While we aim for the
          Services to be highly reliable and available, they are not intended
          to be reliable or available 100% of the time. The Products and
          Services are subject to sporadic interruptions and failures for a
          variety of reasons, including those beyond <span class="text-capitalize">{{this.$shop("name")}}</span>'s control, such as
          wi-fi intermittency, service provider uptime, and mobile operators,
          among others. <span class="text-capitalize">{{this.$shop("name")}}</span> is not responsible for any damages incurred by
          the failure or delay of the Services or Products.
        </p>

        <p class="font-weight-bold">
          C. No Life Safety, Security or Critical Uses of the Products and
          Services
        </p>
        <p>
          THE <span class="text-capitalize">{{this.$shop("name")}}</span> PRODUCTS AND SERVICES ARE NOT A MONITORED EMERGENCY
          NOTIFICATION SYSTEM AND UNDER NO CIRCUMSTANCES WILL <span class="text-capitalize">{{this.$shop("name")}}</span> DISPATCH
          EMERGENCY SERVICES TO YOU IN THE EVENT OF AN EMERGENCY. Services are
          provided for informational purposes only; the Products and Services,
          whether standing alone or when interfaced with third party products
          and services, are not certified for emergency response, unless
          expressly approved and certified by <span class="text-capitalize">{{this.$shop("name")}}</span>. Further, it is your
          responsibility to determine the appropriate response to all
          information you receive from the Services. All life threatening,
          safety and emergency events should be directed to the appropriate
          response services.
        </p>
        <p>
          We make no warranty or representation that use of any Products or
          Services will affect or increase your level of safety.
        </p>

        <p class="font-weight-bold">
          D. Service Interruptions and Failures; No Refund, Credits or Rebate
        </p>
        <p>
          The Services may be suspended temporarily, without notice, for
          security reasons, systems failure, maintenance and repair, or other
          circumstances. You will not be entitled to any refund, credits or
          rebate, or any other compensation, for such suspensions. <span class="text-capitalize">{{this.$shop("name")}}</span> does
          not offer any specific uptime guarantee for the Services.
        </p>

        <p class="font-weight-bold">E. Customer Support</p>
        <p>
          If you have any questions or concerns regarding the Products, the
          Services or the Terms, please contact <span class="text-capitalize">{{this.$shop("name")}}</span> at
          <a :href="`mailto:${this.$shop('config.contacts.customer_service')}`">{{
              this.$shop('config.contacts.customer_service')
            }}</a
          >. Customer Support is subject to availability of applicable <span class="text-capitalize">{{this.$shop("name")}}</span>
          personnel and is limited to responding to inquiries sent through
          standard email and other established <span class="text-capitalize">{{this.$shop("name")}}</span> communication channels.
        </p>
        <p>
          <span class="text-capitalize">{{this.$shop("name")}}</span> does not provide any commitments or guarantees as to response
          times or as to whether problems or concerns can be resolved or
          addressed. Any customer service and support offered and provided by
          <span class="text-capitalize">{{this.$shop("name")}}</span> is not an emergency or 911 service or a dispatch center, an
          emergency service provider or dispatch service, or a lifesaving
          solution for people at risk in their home or otherwise.
        </p>
        <p class="text-uppercase">
          PLEASE DO NOT CONTACT CUSTOMER SERVICE OR SUPPORT OFFERED BY <span class="text-capitalize">{{this.$shop("name")}}</span> WITH ANY
          LIFE/SAFETY OR SECURITY EMERGENCY, MEDICAL EMERGENCY, OR ANY OTHER
          EMERGENCY. IF YOU HAVE ANY SUCH EMERGENCY, YOU SHOULD IMMEDIATELY
          CONTACT THE POLICE, FIRE DEPARTMENT, 911 OR APPROPRIATE EMERGENCY
          RESPONSE SERVICE.
        </p>

        <p class="font-weight-bold">F. Disclaimer of Warranties</p>
        <p>
          Your use of our Products and Services is at your sole risk. Except
          as otherwise provided in a writing by us, our Products and Services
          and any content therein, including User Content, are provided "as
          is" and "as available" without warranties of any kind, either
          express or implied, including, but not limited to, implied
          warranties of merchantability, fitness for a particular purpose,
          title, and non-infringement. In addition, <span class="text-capitalize">{{this.$shop("name")}}</span> does not represent
          or warrant that our Services are accurate, complete, reliable,
          current or error-free. While <span class="text-capitalize">{{this.$shop("name")}}</span> attempts to make your use of our
          Services and any content therein safe, we cannot and do not
          represent or warrant that our Services or servers are free of
          viruses or other harmful components. You assume the entire risk as
          to the quality and performance of the Services.
        </p>
        <p>
          Some jurisdictions do not allow or limit the exclusion of certain
          warranties, so the disclaimers and limitations above may not apply
          to you to the extent applicable law so requires.
        </p>

        <p class="font-weight-bold">G. Release</p>
        <p>
          To the fullest extent permitted by applicable law, you release
          <span class="text-capitalize">{{this.$shop("name")}}</span>, its subsidiaries and affiliates, and each of our respective
          licensors, officers, directors, agents, partners and employees, from
          responsibility, losses, liability, claims, demands and/or damages
          (actual and consequential) of every kind and nature, known and
          unknown (including, but not limited to, claims of negligence,
          personal injury or loss of life), arising out of or related to
        </p>
        <ol class="list-roman">
          <li>your use of the Products and Services,</li>
          <li>disputes between users and</li>
          <li>
            the acts or omissions of third parties, including as they may
            relate to Third-Party Materials or Third Party Content. If you are
            a consumer who resides in California, you hereby waive your rights
            under California Civil Code § 1542, which provides: "A general
            release does not extend to claims which the creditor does not know
            or suspect to exist in his or her favor at the time of executing
            the release, which if known by him or her must have materially
            affected his or her settlement with the debtor."
          </li>
        </ol>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.indemnity">
          INDEMNITY FOR THIRD-PARTY ACTIONS
        </v-card-subtitle>
        <p>
          To the fullest extent permitted by applicable law, you will
          indemnify, defend and hold harmless <span class="text-capitalize">{{this.$shop("name")}}</span>, our affiliates and each
          of our respective officers, directors, employees, agents, licensors
          and suppliers (individually and collectively, the "<span class="text-capitalize">{{this.$shop("name")}}</span> Parties")
          from and against any and all losses, liabilities, claims, demands,
          damages, expenses or costs ("Claims") arising out of or related to:
        </p>
        <ol class="list-roman">
          <li>your access to or use of the Products or Services;</li>
          <li>your User Content or Feedback;</li>
          <li>your violation of these Terms;</li>
          <li>
            your violation, misappropriation or infringement of any rights of
            another (including intellectual property rights or privacy
            rights); or
          </li>
          <li>
            your conduct in connection with the Products or Services. Nothing
            contained herein will be construed to require any indemnification
            which would render or make this clause, in whole or in part, void
            and/or unenforceable under applicable law. However, your
            indemnification obligation will not apply to any willful, wanton,
            intentional or reckless misconduct of the <span class="text-capitalize">{{this.$shop("name")}}</span> Parties, or gross
            negligence of the <span class="text-capitalize">{{this.$shop("name")}}</span> Parties in those states that do not
            permit indemnification for gross negligence. This indemnity is in
            addition to, and not in lieu of, any other indemnities set forth
            in a written agreement between you and <span class="text-capitalize">{{this.$shop("name")}}</span> or the other <span class="text-capitalize">{{this.$shop("name")}}</span>
            Parties.
          </li>
        </ol>
        <p>
          You will promptly notify <span class="text-capitalize">{{this.$shop("name")}}</span> Parties of any third-party Claims,
          cooperate with <span class="text-capitalize">{{this.$shop("name")}}</span> Parties in defending such Claims and pay all
          fees, costs and expenses associated with defending such Claims
          (including, but not limited to, attorneys' fees). <span class="text-capitalize">{{this.$shop("name")}}</span> reserves
          the right, at your expense, to assume the exclusive defense and
          control of any matter for which you are required to indemnify
          <span class="text-capitalize">{{this.$shop("name")}}</span>, and you will cooperate with our defense of such third-party
          Claims. You will not to settle any third-party Claim without
          <span class="text-capitalize">{{this.$shop("name")}}</span>'s prior written consent. <span class="text-capitalize">{{this.$shop("name")}}</span> will use reasonable efforts
          to notify you of any such claim, action or proceeding upon becoming
          aware of it.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.modification">
          MODIFICATION OR TERMINATION OF SERVICES
        </v-card-subtitle>
        <p>
          <span class="text-capitalize">{{this.$shop("name")}}</span> reserves the right, in its sole discretion, at any time to
          modify, augment, limit, suspend, discontinue or terminate any aspect
          of the Products and Services without advance notice. All
          modifications and additions to the Products and Services will be
          governed by the Terms or Supplemental Terms, unless otherwise
          expressly stated by <span class="text-capitalize">{{this.$shop("name")}}</span> in writing. <span class="text-capitalize">{{this.$shop("name")}}</span> will not be liable to
          you or to any third-party for any modification, suspension or
          discontinuance of the Products and Services or any part thereof.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.liability">
          LIMITATION OF LIABILITY
        </v-card-subtitle>
        <p class="text-uppercase">
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, <span class="text-capitalize">{{this.$shop("name")}}</span> AND THE
          OTHER <span class="text-capitalize">{{this.$shop("name")}}</span> PARTIES WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF
          LIABILITY—WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY, WARRANTY, OR OTHERWISE—FOR ANY INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL OR SPECIAL DAMAGES OR LOST PROFITS, EVEN IF
          <span class="text-capitalize">{{this.$shop("name")}}</span> OR THE OTHER <span class="text-capitalize">{{this.$shop("name")}}</span> PARTIES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p class="text-uppercase">
          THE TOTAL LIABILITY OF <span class="text-capitalize">{{this.$shop("name")}}</span> AND THE OTHER <span class="text-capitalize">{{this.$shop("name")}}</span> PARTIES FOR ANY
          CLAIM ARISING OUT OF OR RELATING TO THESE TERMS OR OUR PRODUCTS OR
          SERVICES, REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE
          GREATER OF $500 OR THE AMOUNT PAID BY YOU TO PURCHASE OUR PRODUCTS
          OR USE OUR SERVICES, AS APPLICABLE.
        </p>
        <p class="text-uppercase">
          THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE
          LIABILITY FOR THE GROSS NEGLIGENCE, FRAUD OR INTENTIONAL MISCONDUCT
          OF THE <span class="text-capitalize">{{this.$shop("name")}}</span> PARTIES OR FOR ANY OTHER MATTERS IN WHICH LIABILITY
          CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. ADDITIONALLY,
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
          EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.dispute">
          DISPUTE RESOLUTION AND ARBITRATION/WAIVER OF CLASS ACTION AND
          JURY TRIAL
        </v-card-subtitle>
        <p class="text-uppercase">
          PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO
          ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH <span class="text-capitalize">{{this.$shop("name")}}</span> AND LIMITS THE
          MANNER IN WHICH YOU CAN SEEK RELIEF FROM US. FOLLOW THE INSTRUCTIONS
          BELOW IF YOU WISH TO OPT OUT OF THE REQUIREMENT OF ARBITRATION ON AN
          INDIVIDUAL BASIS. NO CLASS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS
          ARE ALLOWED UNDER THIS ARBITRATION AGREEMENT. IN ADDITION,
          ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR HAVING A JURY
          TRIAL.
        </p>
        <p class="text-uppercase">
          ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS OR OUR SERVICES
          IS PERSONAL TO YOU AND <span class="text-capitalize">{{this.$shop("name")}}</span> AND THAT ANY DISPUTE WILL BE RESOLVED
          SOLELY THROUGH INDIVIDUAL ACTION, AND WILL NOT BE BROUGHT AS A CLASS
          ARBITRATION, CLASS ACTION OR ANY OTHER TYPE OF REPRESENTATIVE
          PROCEEDING.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.law">
          GOVERNING LAW AND VENUE
        </v-card-subtitle>
        <p>
          Any dispute arising from these Terms and your use of the Products or
          Services will be governed by and construed and enforced in
          accordance with the laws of the State of Washington, except to the
          extent preempted by U.S. federal law, without regard to conflict of
          law rules or principles (whether of the State of Washington or any
          other jurisdiction) that would cause the application of the laws of
          any other jurisdiction. If any dispute between the parties is not
          subject to arbitration or cannot be heard in small claims court,
          then the state and federal courts located in King County,
          Washington, will have exclusive jurisdiction. You waive any
          objection to venue in any such courts.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.general">
          GENERAL
        </v-card-subtitle>
        <p class="font-weight-bold">A. Severability; Waiver</p>
        <p>
          If any provision or part of a provision of these Terms is unlawful,
          void or unenforceable, that provision or part of the provision is
          deemed severable from these Terms and does not affect the validity
          and enforceability of any remaining provisions.
        </p>
        <p>
          The failure or delay of <span class="text-capitalize">{{this.$shop("name")}}</span> to exercise or enforce any right,
          remedy or provision of the Terms will not constitute a waiver of
          such right, remedy or provision. All waivers of any terms or
          conditions in these Terms must be in writing signed by the waiving
          party.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.notifications">
          Notifications
        </v-card-subtitle>
        <p>
          <span class="text-capitalize">{{this.$shop("name")}}</span> may provide notices to you with respect to these Terms, the
          Products or Services, or as required by law, by posting such notices
          on the Site and/or through the <span class="text-capitalize">{{this.$shop("name")}}</span> App and/or by sending them to
          you via email to the primary email address associated with your
          Account (if any) and/or to the mobile number or messaging address
          (if any) you provided to us in connection with your Account. Any
          such notices will be deemed properly and timely given to you
          hereunder. <span class="text-capitalize">{{this.$shop("name")}}</span> is not responsible for any automatic filtering
          that you or your network provider may apply to email or mobile
          notifications. <span class="text-capitalize">{{this.$shop("name")}}</span> recommends adding
          <a :href="`mailto:${this.$shop('config.contacts.customer_service')}`">{{
              this.$shop('config.contacts.customer_service')
            }}</a>
          email addresses to your email address book to help ensure that you
          receive email notifications from <span class="text-capitalize">{{this.$shop("name")}}</span>. You consent to the use of:
        </p>
        <ol class="list-roman">
          <li>
            electronic means to provide you with any notices given pursuant to
            these Terms; and
          </li>
          <li>
            electronic records to store information related to these Terms or
            your use of the Products or Services.
          </li>
        </ol>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "services",
  mounted() {
    const sidebar_menus = []
    for(const [key, element] of Object.entries(this.$refs)){
      if(key.startsWith("anchors")){
        const id = key.split(".")[1]
        element.setAttribute("id", id)
        sidebar_menus.push({
          href: `#${id}`,
          title: element.textContent.trim().toUpperCase()
        })
      }
    }

    this.$emit("populated", sidebar_menus)
  }
};
</script>

<style scoped>
ul, ol{
  margin-bottom: 7px;
}

.list-roman li {
  list-style-type: lower-roman;
}

.list-alpha li {
  list-style-type: lower-alpha;
}
</style>
