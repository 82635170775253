var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[(this.isPageLoading)?_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"article, list-item-two-line, paragraph, actions"}}):_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center text-uppercase"},[_vm._v(" Forget Password ")]),_c('ValidationObserver',{ref:"observer"},[_c('v-form',{attrs:{"disabled":_vm.isVerifying}},[_c('ValidationProvider',{ref:"userNameValidator",attrs:{"name":"login username","rules":_vm.rules.username,"customMessages":{required: 'please fill in {_field_}'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.isSubmitting || _vm.verifyMode,"label":"Username","required":"","error-messages":errors,"hint":"If you registered using your email, please fill in the email, otherwise fill in your username","autocomplete":"off"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.username"}})]}}])}),_c('v-expand-transition',[_c('transition-group',[(_vm.verifyMode)?[_c('v-alert',{key:"alert",attrs:{"icon":"envelopeOpenText","prominent":"","text":"","type":"info","transition":"expand-transition"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"v-icon notranslate v-alert__icon mdi mdi-shield-lock-outline theme--dark info--text"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope-open-text']}})],1)]),_c('span',[_vm._v(" An email contained verification code has sent to "),_c('a',{attrs:{"target":"_blank","href":("mailto:" + (this.resEmail))}},[_vm._v(_vm._s(this.resEmail))]),_vm._v(", please check your inbox and get the verification code. ")]),_c('v-divider',{staticClass:"my-4 info",staticStyle:{"opacity":"0.22"}}),_c('span',{staticClass:"font-italic text-caption"},[_vm._v(" Note*: Don't forget to check your junk box if you can't find the email. ")])],2),_c('ValidationProvider',{key:"model.verify_code",attrs:{"name":"verification code","rules":_vm.rules.verify_code,"customMessages":{required: 'please fill in the {_field_} received'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Verification code","required":"","error-messages":errors,"hint":"Please fill in the verification code received","autocomplete":"off"},model:{value:(_vm.verify_code),callback:function ($$v) {_vm.verify_code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"verify_code"}})]}}],null,false,280804341)}),_c('ValidationProvider',{key:"changePassModel.new_password",attrs:{"name":"new login password","rules":_vm.rules.new_password,"customMessages":_vm.valiMessages.new_password,"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"New Password","type":"password","required":"","error-messages":errors,"counter":"","hint":"Please fill in your new login password"},model:{value:(_vm.changePassModel.new_password),callback:function ($$v) {_vm.$set(_vm.changePassModel, "new_password", $$v)},expression:"changePassModel.new_password"}})]}}],null,false,272510806)}),_c('ValidationProvider',{key:"changePassModel.confirm_new_password",attrs:{"name":"confirm password","rules":_vm.rules.confirm_new_password,"customMessages":_vm.valiMessages.confirm_new_password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm New Password","type":"password","required":"","error-messages":errors,"hint":"Please fill in your new login password again"},model:{value:(_vm.changePassModel.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.changePassModel, "confirm_new_password", $$v)},expression:"changePassModel.confirm_new_password"}})]}}],null,false,3369147115)})]:_vm._e()],2)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.isSubmitting,"disabled":_vm.resendCountDown > 0},on:{"click":_vm.send}},[(_vm.verifyMode)?[_vm._v(" Re-Send ")]:[_vm._v(" Send Verification Code ")],(_vm.resendCountDown > 0)?[_vm._v(" ("+_vm._s(_vm.resendCountDown)+" s) ")]:_vm._e()],2),(_vm.verifyMode)?[_c('v-btn',{attrs:{"outlined":"","loading":_vm.isVerifying},on:{"click":_vm.verify}},[_vm._v(" Verify ")])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }