<template>
  <v-snackbar
    v-model="alertAttrs.visible"
    v-bind="alertAttrs"
  >
    <div v-for="(content, $index) in alertAttrs.contents" :key="$index">
      {{ content }}
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="alertAttrs.visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data(){
    return {
      alertAttrs: this._alertAttrs
    }
  }
};
</script>

<style scoped>

</style>
