<template>
  <v-flex>
    <v-card flat>
      <v-card-title class="text-uppercase justify-center">
        {{ this.title }}
      </v-card-title>

      <v-alert border="top" colored-border :type="this.type" elevation="2" class="text-center">
        <span v-html="this.content">
        </span>
      </v-alert>

      <v-card-actions class="justify-center">
        <v-btn :to="{ name: 'signin' }" text v-if="this.actions.includes('signin')" replace>
          Sign In
        </v-btn>
        <v-btn :to="{ name: 'index' }" text v-if="this.actions.includes('home')" replace>
          Home
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "Error",
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    },
    type: {
      type: String,
      default: "info"
    },
    actions: {
      type: Array,
      default: () => []
    },
    shouldRedirect: {
      type: Boolean,
      default: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if(vm.shouldRedirect && from && from.fullPath){
        vm.$store.commit("redirection/setURL", from.fullPath)
      }
    })
  },
  created() {
    if(!this.title || !this.content){
      this.$router.replace({ name: "index" });
    }
  }
};
</script>

<style scoped>

</style>
