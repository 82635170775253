import VueRouter from "vue-router";
import sso_controller from "./sso";
import signout_controller from "./signout"
import fujicam_controller from "./vendors/fujicam"

export default ({ Vue, store }) => {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      name: "index",
      redirect: { name: "signin" },
      meta: {
        title: "User Center",
        disallowedIfLoggedIn: true
      },
      component: () => import(/* webpackChunkName: "index" */ "../views/layouts/1col-centered"),
      children:[
        {
          path: "notice",
          name: "notice",
          meta: {
            title: "Notice"
          },
          props: true,
          component: () => import(/* webpackChunkName: "error" */ "../views/Notice")
        },
        {
          path: "signup",
          name: "signup",
          meta: {
            title: "Sign Up",
            disallowedIfLoggedIn: true
          },
          component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp")
        },
        {
          path: "signin",
          name: "signin",
          meta: {
            title: "Sign In",
            disallowedIfLoggedIn: true
          },
          // route level code-splitting
          // this generates a separate chunk (signin.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "signin" */ "../views/SignIn")
        },
        {
          path: "activate",
          meta: {
            title: "Activate Account",
            disallowedIfLoggedIn: true
          },
          props: true,
          name: "activate",
          component: () =>
            import(/* webpackChunkName: "activate" */ "../views/Activate")
        },
        {
          path: "bind-email",
          name: "bindEmail",
          meta: {
            title: "Bind Email",
            disallowedIfLoggedIn: true
          },
          component: () =>
            import(/* webpackChunkName: "bindEmail" */ "../views/BindEmail")
        },
        {
          path: "change-password",
          name: "changePassword",
          meta: {
            title: "Forget Password",
            disallowedIfLoggedIn: true
          },
          component: () =>
            import(/* webpackChunkName: "changePassword" */ "../views/ChangePassword")
        },
        {
          path: "register-order",
          name: "registerOrder_unsecured",
          meta: {
            title: "Register Order",
            disallowedIfLoggedIn: { name: "registerOrder" },
          },
          component: () =>
            import(
              /* webpackChunkName: "registerOrder" */ "../views/user/RegisterOrder"
              ),
        }
      ]
    },
    {
      path: "/user/",
      name: "userIndex",
      meta: {
        title: "My Account",
        requiresAuth: true
      },
      redirect: { name: "userProfile" },
      component: () => import(/* webpackChunkName: "userIndex" */ "../views/layouts/user/top-left-nav"),
      children: [
        {
          path: "profile",
          name: "userProfile",
          meta: {
            title: "My Profile",
            icon: ["fas", "user-circle"],
            requiresAuth: true
          },
          component: () =>
            import(/* webpackChunkName: "userProfile" */ "../views/user/Profile")
        },
        {
          path: "change-password",
          name: "userChangePassword",
          meta: {
            title: "Change Password",
            icon: ["fas", "unlock-alt"],
            requiresAuth: true
          },
          component: () =>
            import(
              /* webpackChunkName: "changePassword" */ "../views/user/ChangePassword"
            ),
        },
        {
          path: "register-order",
          name: "registerOrder",
          meta: {
            title: "Register Order",
            icon: ["fas", "gifts"],
            requiresAuth: true
          },
          component: () =>
            import(
              /* webpackChunkName: "registerOrder" */ "../views/user/RegisterOrder"
              ),
        }
      ]
    },
    {
      path: "/terms",
      name: "terms",
      redirect: { name: "privacy" },
      component: () => import(/* webpackChunkName: "terms" */ "../views/layouts/2cols-left-nav"),
      children:[
        {
          path: "privacy",
          name: "privacy",
          meta: {
            title: "Privacy Terms"
          },
          component: () =>
            import(
              /* webpackChunkName: "privacy" */ "../views/terms/Privacy"
              ),
        },
        {
          path: "services",
          name: "services",
          meta: {
            title: "Terms of Services"
          },
          component: () =>
            import(
              /* webpackChunkName: "services" */ "../views/terms/Services"
              ),
        },
        {
          path: "returns",
          name: "returns",
          meta: {
            title: "Returns and Warranty Policy"
          },
          component: () =>
            import(
              /* webpackChunkName: "returns" */ "../views/terms/ReturnsWarranty"
              ),
        }
      ]
    },
    sso_controller({ Vue, store }),
    signout_controller({ Vue, store }),
    fujicam_controller({ Vue, store })
  ];

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

  Vue.router = router

  router.beforeEach( (to, from, next) => {
    if (to.name) {
      // Start the route progress bar.
      store.commit("appState/appLoading", true)
    }
    const isLoggedIn = store.getters["userContext/isLoggedIn"]()
    if (to.meta.requiresAuth && !isLoggedIn) {
      return next({ name: "sso", query:{
          redirect: to.fullPath
        }
      });
    }

    if (to.meta.disallowedIfLoggedIn && isLoggedIn) {
      if(to.meta.disallowedIfLoggedIn === true){
        return next({ name: "userIndex" });
      }
      else if(typeof to.meta.disallowedIfLoggedIn === "object"){
        return next(
          Object.assign(to.meta.disallowedIfLoggedIn, {
            query: to.query,
            params: to.params,
          })
        );
      }
    }

    if (to.meta.title) {
      document.title = to.meta.title
    }

    next();
  });

  router.afterEach(() => {
    // Complete the animation of the route progress bar.
    store.commit("appState/appLoading", false)
  });

  return router
};
