<template>
  <v-flex>
    <v-card flat>
      <v-card-title class="justify-center text-uppercase">
        Returns and Warranty Policy
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="font-italic">
          Effective January 01, 2021
        </p>
        <div>
          <p>
            Thank you for shopping with <span class="text-capitalize">{{this.$shop("name")}}</span>! We want you to have a great experience shopping at {{this.$shop("domain")}} (referred to as the “<span class="text-capitalize">{{this.$shop("name")}}</span> Store” in this policy).
          </p>
          <p>
            As with any shopping experience, there are terms and conditions that apply to transactions made in the <span class="text-capitalize">{{this.$shop("name")}}</span> Store. All <span class="text-capitalize">{{this.$shop("name")}}</span> products and services are governed by the <router-link :to="{name: 'services'}" target="_blank">Terms of Use</router-link>, <router-link :to="{name: 'privacy'}" target="_blank">Privacy Policy</router-link>, and this Standard Return Policy and Limited Warranty.
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle class="pl-0 font-weight-bold" ref="anchors.returns">
          Standard Return Policy
        </v-card-subtitle>

        <p>
          We hope that you absolutely love your <span class="text-capitalize">{{this.$shop("name")}}</span> products! However, if you need to return an item, please review the following terms related to returning a product.
        </p>

        <p>
          Any undamaged product purchased from the <span class="text-capitalize">{{this.$shop("name")}}</span> Store can be returned to <span class="text-capitalize">{{this.$shop("name")}}</span> within 30 days after the date of purchase, provided that you follow the return process outlined in How to submit a return. Once your return has been received and processed, <span class="text-capitalize">{{this.$shop("name")}}</span> will issue a refund to the original payment method. For items given or received as a gift, <span class="text-capitalize">{{this.$shop("name")}}</span> is only able to issue a refund to the payment method used by the purchaser.
        </p>

        <p>
          The refund will include the purchase price of the returned product and any associated sales tax. The shipping charge associated with the original purchase will not be refunded.
        </p>

        <p>
          Additional terms and conditions:
        </p>

        <ul>
          <li>
            Only items that have been purchased directly from <span class="text-capitalize">{{this.$shop("name")}}</span> through the <span class="text-capitalize">{{this.$shop("name")}}</span> Store can be returned to <span class="text-capitalize">{{this.$shop("name")}}</span>. Items purchased through <span class="text-capitalize">{{this.$shop("name")}}</span>’ authorized resellers and distributors must be returned in accordance with their respective returns and refunds policy.
          </li>
          <li>
            You must provide your <span class="text-capitalize">{{this.$shop("name")}}</span> Store order number to initiate a return. For items received as a gift, you will need to provide the order number received by the purchaser. <span class="text-capitalize">{{this.$shop("name")}}</span> is only able to issue a refund to the original payment method used by the purchaser.
          </li>
          <li>
            Items must be returned with all included accessories and packaging. Please make sure that the item you are returning is repackaged with all the cords, adapters and documentation that were included when you received it.
          </li>
          <li class="text-uppercase">
            <span class="text-capitalize">{{this.$shop("name")}}</span> will provide a pre-paid return shipping label that you can use to ship your return to us. You will not be charged for the return shipping cost. However, the shipping charge associated with your original purchase will not be refunded.
          </li>
        </ul>

        <p class="font-weight-bold">
          Holiday Return Policy
        </p>
        <p>
          We are extending the normal 30-day return window for items ordered between November 1 and December 31. Items ordered from the <span class="text-capitalize">{{this.$shop("name")}}</span> Store between November 1 and December 31 of this year may be returned until January 31 of the following year for a full refund, subject to the other terms of this Return Policy.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle  class="pl-0 font-weight-bold" ref="anchors.warranty">
          Limited Warranty
        </v-card-subtitle>

        <p>
          For a period of one (1) year from the date of purchase of <span class="text-capitalize">{{this.$shop("name")}}</span> product, at its sole option, repair or replace any <span class="text-capitalize">{{this.$shop("name")}}</span> products that malfunction due to defective parts or workmanship at no charge to you. This warranty is not transferable and applies only to the original purchaser. <span class="text-capitalize">{{this.$shop("name")}}</span> may, in its sole discretion, make any repair or replacement with new or refurbished product or components. If the product or component requiring repair or replacement is no longer available, <span class="text-capitalize">{{this.$shop("name")}}</span> may, in its sole discretion, replace such product with a similar product of similar function.
        </p>

        <p>
          The following products are <span class="font-weight-bold text-uppercase">NOT</span> covered by the Limited Warranty:
        </p>
        <ul>
          <li>
            Products submitted beyond the original one-year limited warranty period;
          </li>
          <li>
            After the initial 30 days after purchase, any product issue that is not quality-related;
          </li>
          <li>
            Products submitted without valid proof of purchase;
          </li>
          <li>
            Products that are physically located outside the United States;
          </li>
          <li>
            Products purchased from unauthorized resellers;
          </li>
          <li>
            Products that are lost or stolen;
          </li>
          <li>
            Products received without charge in a <span class="text-capitalize">{{this.$shop("name")}}</span> giveaway or donation, or as samples;
          </li>
          <li>
            Products on which repairs have been attempted;
          </li>
          <li>
            Product damaged from unauthorized service or modification;
          </li>
          <li>
            Products damaged due to acts of God (including but not limited to lightning, flood, tornado, earthquake, or hurricane), and consumable parts (including batteries);
          </li>
          <li>
            Products damaged due to misuse, abuse, negligence or casualty (e.g., fire, falls, extreme temperatures, water) or other operating conditions contrary to the <span class="text-capitalize">{{this.$shop("name")}}</span> documentation;
          </li>
        </ul>

        <p class="font-weight-bold text-uppercase">
          THEFT PROTECTION
        </p>
        <p>
          If your <span class="text-capitalize">{{this.$shop("name")}}</span> camera or doorbell product is stolen at any time after your purchase, <span class="text-capitalize">{{this.$shop("name")}}</span> will replace the stolen device at no charge to you. This theft protection is not transferable and applies only to the original consumer purchaser. Coverage of this theft protection is limited to one (1) replacement only for each <span class="text-capitalize">{{this.$shop("name")}}</span> camera or doorbell product that you have purchased. Theft protection does not apply to any products purchased from third party sellers on eBay and other online marketplaces. Any claim based on theft of your <span class="text-capitalize">{{this.$shop("name")}}</span> camera or doorbell product must be submitted within fifteen (15) days of the theft and include the original sales receipt, and a copy of a police report evidencing that you reported the theft to the police. Failure to report the theft to police voids this theft protection.
        </p>
      </v-card-text>

      <v-card-text>
        <v-card-subtitle  class="pl-0 font-weight-bold" ref="anchors.replacement">
          How to Obtain Service/replacement
        </v-card-subtitle>

        <p>
          To obtain the benefit of the above limited warranty or theft protection, please contact <span class="text-capitalize">{{this.$shop("name")}}</span> community support at:
          <a :href="`mailto:${this.$shop('config.contacts.customer_service')}`">{{
            this.$shop('config.contacts.customer_service')
          }}</a>.
        </p>

        <p>
          For warranty requests: Please be prepared to describe the product that needs service, the nature of the problem and to provide proof of purchase. You will also be required to return your existing device in accordance with directions that will be provided by <span class="text-capitalize">{{this.$shop("name")}}</span>. <span class="text-capitalize">{{this.$shop("name")}}</span> has no warranty obligations with respect any products that are excluded from warranty pursuant to Limited Warranty, as reasonably determined by <span class="text-capitalize">{{this.$shop("name")}}</span>, and the owner of such device shall bear all shipping costs for the return of such product to owner. Any claim under this Limited Warranty must be submitted to <span class="text-capitalize">{{this.$shop("name")}}</span> before the end of the warranty period described above. Please allow up to fourteen (14) business days for your warranty request to be processed. For replacement requests: Please be prepared to describe the product that needs to be replaced and a copy of the police report (Accordance to THEFT PROTECTION). Please allow up to fourteen (14) business days for your replacement to be processed.
        </p>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "ReturnsWarranty",
  mounted() {
    const sidebar_menus = []
    for(const [key, element] of Object.entries(this.$refs)){
      if(key.startsWith("anchors")){
        const id = key.split(".")[1]
        element.setAttribute("id", id)
        sidebar_menus.push({
          href: `#${id}`,
          title: element.textContent.trim().toUpperCase()
        })
      }
    }

    this.$emit("populated", sidebar_menus)
  }
};
</script>

<style scoped>
ul, ol{
  margin-bottom: 7px;
}
</style>
