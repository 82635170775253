export default ({ store }) => {
  return {
    path: "/signout",
    name: "signout",
    meta: {
      title: "Sign Out",
    },
    beforeEnter: async (to, from, next) => {
      store.commit("appState/appLoading", true);
      const redirect = to.query.redirect || store.getters["redirection/URL"]();
      const finalRedirect = redirect || { name: "index" };
      if (store.getters["userContext/isLoggedIn"]()) {
        try{
          await store.dispatch("userContext/signout");
          // eslint-disable-next-line no-empty
        } catch {}
      }

      if (typeof finalRedirect === "object") {
        next({ ...finalRedirect, replace: true });
        return;
      }

      window.location.href = finalRedirect;
      next();
    }
  }
}
