export default (Vue, { store, router }) => {
  const { $http } = Vue;
  const vue = Vue.prototype;
  $http.interceptors.response.use(
    (response) => {
      const { data: res } = response;
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      if (
        res.errors &&
        res.errors.length &&
        //these codes need to be handled explicitly in views
        !Object.values(vue.$server_configs.codes).includes(res.code)
      ) {
        vue.$alert({
          type: "error",
          contents: res.errors,
        });
        if (vue.$gtag) {
          vue.$gtag.exception({
            description: res.errors.join(";"),
            fatal: false
          });
        }
      }
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if(error.response){
        const { status, statusText } = error.response;
        if (status === 401) {
          store.commit("userContext/signout");
          if(router.currentRoute && router.currentRoute.name !== "notice" && !store.getters["appState/isRouteLoading"]){
            await router.replace({
              name: "notice",
              params: {
                title: "Session Expired",
                type: "warning",
                content:
                  "Your logged-in session has expired, please sign in again.",
                actions: ["home"],
                shouldRedirect: true
              },
            });
          }
          return false;
        }
        else{
          if (vue.$gtag) {
            vue.$gtag.exception({
              description: statusText,
              fatal: true
            });
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
