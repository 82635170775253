<template>
  <v-flex xs12 sm10 md8 lg6 xl5>
    <v-skeleton-loader type="article, list-item-two-line, paragraph, actions" v-if="this.isPageLoading" class="mt-2">
    </v-skeleton-loader>
    <v-card v-else flat>
      <v-card-title class="text-uppercase">
        {{this.$route.meta.title}}
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form :disabled="isSubmitting" ref="form">
            <ValidationProvider v-slot="{ errors }" name="current login password" :rules="rules.old_password" :customMessages="valiMessages.old_password">
              <v-text-field
                v-model="model.old_password"
                label="Current Password"
                type="password"
                required
                :error-messages="errors"
                counter
                hint="Please fill in your current login password"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="new login password" :rules="rules.new_password" :customMessages="valiMessages.new_password" vid="new_password">
              <v-text-field
                v-model="model.new_password"
                label="New Password"
                type="password"
                required
                :error-messages="errors"
                counter
                hint="Please fill in your new login password"
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="confirm new password" :rules="rules.confirm_password" :customMessages="valiMessages.confirm_password">
              <v-text-field
                v-model="model.confirm_password"
                label="Confirm Password"
                type="password"
                required
                :error-messages="errors"
                hint="Please fill in your new login password again"
              ></v-text-field>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" outlined :loading="isSubmitting"> Change Password </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "ChangePassword",
  data: () => ({
    isPageLoading: true,
    isSubmitting: false,
    model: {
      old_password: null,
      new_password: null,
      confirm_password: null
    },
    rules: null,
    valiMessages: null
  }),
  methods:{
    async submit(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isSubmitting = true
          const { data: res } = await this.$http.post(
            "/account/changePassword",
            {
              old_password: this.model.old_password,
              new_password: this.model.new_password
          });

          if (!res.errors || !res.errors.length){
            this.$alert({
              contents: ["Your login password has been changed successfully."]
            })
            this.$refs.form.reset()
            this.$refs.observer.reset()
          }
          this.isSubmitting = false
        }
      })
    }
  },
  async mounted() {
    this.isPageLoading = true
    const { data: rules } = await this.$http.post("/user/validationRules",{
      vType: "vee",
    });
    this.rules = {
      old_password: {
        required: "Please fill in your current login password"
      },
      new_password: { ...rules.password, required: "Please fill in your new login password" },
      confirm_password: {
        required: "Please fill in your new login password again",
        confirmed: {
          target: "new_password",
          message: "Confirmed password is different from the new password"
        }
      }
    }
    this.valiMessages = this.$utils.normalizeRuleMessages(this.rules)
    this.isPageLoading = false
  }
};
</script>

<style scoped>

</style>
