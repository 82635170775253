import utils from "./utils";
const config = utils.getConfig();
const { key } = config.storage;
export default {
  get: function(propName) {
    const jStr = localStorage.getItem(key) || "{}";
    return propName ? JSON.parse(jStr)[propName] : JSON.parse(jStr);
  },
  set: function(propName, obj) {
    const prop = this.get()
    prop[propName] = obj
    localStorage.setItem(key, JSON.stringify(prop))
  },
  del: function(propName){
    const prop = this.get()
    delete prop[propName]
    localStorage.setItem(key, JSON.stringify(prop))
  }
}
