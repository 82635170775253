const config = require(`../config.${process.env.NODE_ENV}`).default;
export default {
  getConfig: () => {
    return config;
  },
  normalizeRuleMessages: (modelRules) => {
    const fieldMessages = {}
    for(const [field, rules] of Object.entries(modelRules)){
      const messages = {}
      for(const [ruleName, ruleConfig] of Object.entries(rules)){
        const message = typeof ruleConfig === "string" ? ruleConfig : ruleConfig.message
        if(message){
          messages[ruleName] = message
        }
      }
      fieldMessages[field] = messages
    }

    return fieldMessages
  },
  isOwnOrigin: function(url) {
    if(url){
      if(!this.isAbsoluteURL(url)){
        return true
      }
      return new URL(url).origin === location.origin
    }
    return false
  },
  isAbsoluteURL: (path) => {
    const regex = new RegExp("^(?:[a-z]+:)?//", "i");
    return regex.test(path)
  }
};
