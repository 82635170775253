<template>
  <v-app>
    <v-layout v-if="this.$store.getters['appState/isAppLoading']" align-center justify-center>
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      >
        <v-icon>$logoSingle</v-icon>
      </v-progress-circular>
    </v-layout>
    <router-view v-else />
    <alert></alert>
  </v-app>
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
@import "./assets/css/main.css";
</style>
