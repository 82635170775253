export default (app, config) => {
  const axios = require("axios").default.create({
    baseURL: config.webapi.baseUrl,
    method: "post",
  });

  //send jwt token to api
  axios.interceptors.request.use((config) => {
    if(app.store){
      const userAuth = app.store.getters["userContext/auth"]();
      if (userAuth && userAuth.token) {
        config.headers.Authorization = `Bearer ${userAuth.token}`;
      }
    }

    return config;
  });

  //set token to refresh rolling session expires
  axios.interceptors.response.use((response) => {
    const token = response.headers["set-token"]
    if(token){
      app.store.commit("userContext/setToken", token)
    }
    return response;
  });

  //for Vue
  app.$http = axios;

  //for "this" keyword
  Object.defineProperties(app.prototype, {
    $http: {
      get: function get() {
        return axios;
      },
    },
  });
  return axios;
};
