<template>
  <v-flex>
    <v-card flat>
      <v-card-title class="justify-center text-uppercase">
        Activate Your Account
      </v-card-title>
      <v-alert border="top" type="info" outlined v-if="origin === 'signin'">
        You need to activate your account before proceed
      </v-alert>
      <v-alert
        prominent
        text
        type="info"
        transition="expand-transition"
      >
        <template slot="prepend">
          <i class="v-icon notranslate v-alert__icon mdi mdi-shield-lock-outline theme--dark info--text">
            <font-awesome-icon :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
          </i>
        </template>
        <span>
            An email contained verification code has sent to <a target="_blank" :href="`mailto:${localUser.email}`">{{ localUser.email }}</a>,
            please check your inbox and confirm the verification code.
          </span>
        <v-divider class="my-4 info" style="opacity: 0.22"/>
        <span class="font-italic text-caption">
            Note*: Don't forget to check your junk box if you can't find the email.
          </span>
      </v-alert>
      <ValidationObserver ref="observer">
        <v-form :disabled="isSubmitting || isSending">
          <ValidationProvider v-slot="{ errors }" name="verification code" :rules="rules.verify_code" :customMessages="{required: 'please fill in the {_field_} received'}">
            <v-text-field
              v-model.trim="model.verify_code"
              label="Verification code"
              required
              :error-messages="errors"
              hint="Please fill in the verification code received"
              autocomplete="off"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" outlined :loading="isSubmitting" :disabled="isSending || isSubmitting"> Verify </v-btn>

        <v-btn @click="resend" outlined :loading="isSending" color="warning"  :disabled="(isSending || isSubmitting) || resendCountDown > 0">
          Re-send
          <template v-if="resendCountDown > 0">
            ({{ resendCountDown }} s)
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "Activate",
  props: ["origin"],
  data: function() {
    return {
      isSubmitting: false,
      isSending: false,
      resendCountDown: 0,
      localUser: this.$user,
      model: {
        verify_code: null
      },
      rules: {
        verify_code: {
          required: true
        }
      }
    }
  },
  methods: {
    async submit() {
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isSubmitting = true
          const username = this.$user.username
          const { data: res } = await this.$http.post("/user/activate", {
            username,
            verify_code: this.model.verify_code
          });

          if (!res.errors || !res.errors.length){
            await this.$router.replace({
              name: "notice",
              params: {
                title: "Account Activated",
                type: "success",
                content:
                  "You account has been successfully activated, please sign in.",
                actions: ["signin"],
              },
            });
          }

          this.isSubmitting = false
        }
      })
    },
    resetCountDown(){
      this.resendCountDown = 60;
      const timer = setInterval(() => {
        this.resendCountDown--;

        if (this.resendCountDown === 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
    async resend(){
      this.isSending = true
      const { username } = this.$user
      const { data: res } = await this.$http.post("/user/signup", {
        username
      });

      if (!res.errors || !res.errors.length){
        this.$alert({
          type: "success",
          contents: ["Email has been re-sent"]
        });
        this.resetCountDown()
      }

      this.isSending = false
    }
  },
  mounted() {
    this.resetCountDown()
  }
};
</script>

<style scoped>

</style>
