export default (Vue) => {
  return {
    namespaced: true,
    state: {
      config: null
    },
    getters: {
      getConfigs: state => {
        return state.config;
      }
    },
    mutations: {
      initLoad: (state, { config }) => {
        state.config = config;
      }
    },
    actions: {
      initLoad({ commit }) {
        return Vue.$http.post("/index").then(({ data }) => {
          commit("initLoad", { config: data });
          return data;
        });
      }
    }
  };
};
