<template>
  <v-row>
    <v-col cols="12" sm="7" md="7" lg="7" xl="6">
      <v-card flat>
        <v-card-title class="justify-center text-uppercase">
          Sign In
        </v-card-title>
        <v-sheet class="font-italic font-weight-light" style="font-size: small">
          If you already signed up in <span class="text-capitalize">{{this.$shop("name")}}</span> App, please feel free to use the account to sign in.
        </v-sheet>
        <ValidationObserver ref="observer">
          <v-form :disabled="isSubmitting">
            <ValidationProvider v-slot="{ errors }" name="login user name" :rules="rules.username" :customMessages="{required: 'please fill in your {_field_}'}">
              <v-text-field
                v-model.trim="model.username"
                label="User Name"
                required
                :error-messages="errors"
                hint="Your login username or email"
                autocomplete="off"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="login password" :rules="rules.password" :customMessages="{required: 'please fill in your {_field_}'}">
              <v-text-field
                v-model="model.password"
                label="Password"
                type="password"
                required
                :error-messages="errors"
                counter
                hint="Your login password"
              ></v-text-field>
            </ValidationProvider>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="rememberMe"
                  label="Remember me"
                ></v-checkbox>
              </v-col>
              <v-col class="d-flex align-center">
                <router-link :to="{ name: 'changePassword' }">Forget password?</router-link>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
        <v-card-actions class="justify-center">
          <v-btn @click="submit" outlined :loading="isSubmitting"> Sign In </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col align-self="center" class="d-flex justify-center text-center">
      <v-card flat>
        <v-card-title>
          Not a member yet?
        </v-card-title>
        <v-btn :to="{ name: 'signup' }" outlined>
          Create Account
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      model: {
        username: null,
        password: null
      },
      rememberMe: true,
      rules: {
        username: {
          required: true
        },
        password:{
          required: true
        }
      }
    }
  },
  methods:{
    async submit(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isSubmitting = true
          const { data: res } = await this.$http.post("/user/signin", {
            username: this.model.username,
            password: this.model.password
          });

          const handleCodes = Object.values(this.$server_configs.codes)
          if (!res.errors || !res.errors.length || handleCodes.includes(res.code)) {
            const user = {
              username: this.model.username,
              email: this.model.username
            }
            this.$store.commit("userContext/setUser", user);

            //user not activated, go through activation process
            if(res.code === this.$server_configs.codes.user_not_activated){
              const { data: signupRes } = await this.$http.post(
                "/user/signup",
                {
                  username: this.model.username
                }
              );

              if (!signupRes.errors || !signupRes.errors.length) {
                return await this.$router.replace({
                  name: "activate",
                  params: { origin: "signin" }
                });
              }
            }

            //user email not bound, go through bind email process
            if(res.code === this.$server_configs.codes.email_not_bound){
              return await this.$router.replace({
                name: "bindEmail",
                query:{
                  username: this.model.username
                }
              });
            }

            //write user auth for sign in
            await this.$store.dispatch("userContext/signin", res.data);
            //redirect to sso helper page
            try{
              await this.$router.replace({ name: "sso" });
            }
            //eliminate multi redirect exception as this is a desired behaviour
            // eslint-disable-next-line no-empty
            catch {}
          }

          this.isSubmitting = false
        }

        return valid;
      })
    }
  }
}
</script>
