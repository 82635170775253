import LocalStorage from "../../lib/localStorage";
const KEY_REDIRECT = "redirect";

export default () => {
  return {
    namespaced: true,
    state: {},
    getters: {
      URL: () => () => {
        return LocalStorage.get(KEY_REDIRECT)
      }
    },
    mutations:{
      setURL(state, redirect){
        state.redirect = redirect
        LocalStorage.set(KEY_REDIRECT, redirect)
      },
      delURL(state){
        delete state.redirect
        LocalStorage.del(KEY_REDIRECT)
      }
    },
    /*actions: {
      async redirect({ commit }){
        if (this.getters["userContext/isLoggedIn"]()) {
          const url = this.getters["redirection/URL"]()
          if (url) {
            const { token } = this.getters["userContext/auth"]();
            const urlObj = new URL(url);
            if(token){
              urlObj.searchParams.append("token", token)
            }
            commit("delURL")
            return window.location.replace(urlObj.toString());
          }
          commit("delURL")
          return await Vue.router.push({ name: "userIndex" });
        }

        throw new Error("user not logged in")
      }
    }*/
  }
}
