<template>
  <v-flex>
    <v-skeleton-loader type="article, list-item-two-line, paragraph, actions" v-if="this.isPageLoading" class="mt-2">
    </v-skeleton-loader>
    <v-card v-else flat>
      <v-card-title class="justify-center text-uppercase">
        Forget Password
      </v-card-title>

      <ValidationObserver ref="observer">
        <v-form :disabled="isVerifying">
          <ValidationProvider v-slot="{ errors }" name="login username" :rules="rules.username" :customMessages="{required: 'please fill in {_field_}'}" ref="userNameValidator">
            <v-text-field
              v-model.trim="model.username"
              :disabled="isSubmitting || verifyMode"
              label="Username"
              required
              :error-messages="errors"
              hint="If you registered using your email, please fill in the email, otherwise fill in your username"
              autocomplete="off"
            ></v-text-field>
          </ValidationProvider>

          <v-expand-transition>
            <transition-group>
              <template v-if="verifyMode">
                <v-alert
                  key="alert"
                  icon="envelopeOpenText"
                  prominent
                  text
                  type="info"
                  transition="expand-transition"
                >
                  <template slot="prepend">
                    <i class="v-icon notranslate v-alert__icon mdi mdi-shield-lock-outline theme--dark info--text">
                      <font-awesome-icon :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
                    </i>
                  </template>
                  <span>
                    An email contained verification code has sent to <a target="_blank" :href="`mailto:${this.resEmail}`">{{ this.resEmail }}</a>,
                    please check your inbox and get the verification code.
                  </span>
                  <v-divider class="my-4 info" style="opacity: 0.22"/>
                  <span class="font-italic text-caption">
                    Note*: Don't forget to check your junk box if you can't find the email.
                  </span>
                </v-alert>
                <ValidationProvider key="model.verify_code" v-slot="{ errors }" name="verification code" :rules="rules.verify_code" :customMessages="{required: 'please fill in the {_field_} received'}">
                  <v-text-field
                    v-model.trim="verify_code"
                    label="Verification code"
                    required
                    :error-messages="errors"
                    hint="Please fill in the verification code received"
                    autocomplete="off"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider key="changePassModel.new_password" v-slot="{ errors }" name="new login password" :rules="rules.new_password" :customMessages="valiMessages.new_password" vid="password">
                  <v-text-field
                    v-model="changePassModel.new_password"
                    label="New Password"
                    type="password"
                    required
                    :error-messages="errors"
                    counter
                    hint="Please fill in your new login password"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider key="changePassModel.confirm_new_password" v-slot="{ errors }" name="confirm password" :rules="rules.confirm_new_password" :customMessages="valiMessages.confirm_new_password">
                  <v-text-field
                    v-model="changePassModel.confirm_new_password"
                    label="Confirm New Password"
                    type="password"
                    required
                    :error-messages="errors"
                    hint="Please fill in your new login password again"
                  ></v-text-field>
                </ValidationProvider>
              </template>
            </transition-group>
          </v-expand-transition>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-center">
        <v-btn @click="send" outlined :loading="isSubmitting" :disabled="resendCountDown > 0">
          <template v-if="verifyMode">
            Re-Send
          </template>
          <template v-else>
            Send Verification Code
          </template>
          <template v-if="resendCountDown > 0">
            ({{ resendCountDown }} s)
          </template>
        </v-btn>

        <template v-if="verifyMode">
          <v-btn @click="verify" outlined :loading="isVerifying"> Verify </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "ChangPassword",
  data: () => ({
    isPageLoading: true,
    isSubmitting: false,
    verifyMode: false,
    isVerifying: false,
    resendCountDown: 0,
    model: {
      username: null
    },
    changePassModel:{
      new_password: null,
      confirm_new_password: null
    },
    resEmail: null,
    verify_code: null,
    rules: {
      username: {
        required: true
      },
      verify_code: {
        required: true
      }
    },
    valiMessages: null
  }),
  methods:{
    resetCountDown(){
      this.resendCountDown = 60;
      const timer = setInterval(() => {
        this.resendCountDown--;

        if (this.resendCountDown === 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
    async send(){
      return this.$refs.userNameValidator.validate().then(async ({ valid }) => {
        if (valid) {
          this.isSubmitting = true
          const { data: res } = await this.$http.post("/user/forgetPassword", {
            username: this.model.username
          });

          if (!res.errors || !res.errors.length){
            this.resEmail = res.data
            this.resetCountDown()
            this.verifyMode = true
          }

          this.isSubmitting  =false
        }
      })
    },
    async verify(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isVerifying = true
          const username = this.model.username
          const { data: res } = await this.$http.post("/user/changePassword", {
            username,
            verify_code: this.verify_code,
            new_password: this.changePassModel.new_password
          });

          if (!res.errors || !res.errors.length){
            await this.$router.replace({
              name: "notice",
              params: {
                title: "Password Changed",
                type: "success",
                content:
                  "You login password has been successfully changed, please sign in with your new password.",
                actions: ["signin"],
              },
            });
          }

          this.isVerifying = false
        }
      })
    }
  },
  async mounted(){
    this.isPageLoading = true
    const { data: rules } = await this.$http.post("/user/validationRules",{
      vType: "vee",
    });
    this.rules.new_password = { ...rules.password, required: "Please fill in your new login password" }
    this.rules.confirm_new_password = {
      required: "Please fill in your new login password again",
      confirmed: {
        target: "password",
        message: "Confirmed new password is different from the new password"
      }
    }

    this.valiMessages = this.$utils.normalizeRuleMessages(this.rules)
    this.isPageLoading = false
  }
};
</script>

<style scoped>

</style>
