import Vue from "vue";
import App from "./App.vue";
import Router from "./router";
import Store from "./store";
import Vuetify from "./plugins/vuetify";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import utils from "../lib/utils";
const config = utils.getConfig();
import Axios from "./plugins/axios";
import Validator from "./plugins/validator"
import Alert from "./plugins/alert"
import ErrorHandler from "./plugins/errorHandler"
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);

Object.defineProperties(Vue.prototype, {
  $config: {
    get: () => {
      return config;
    },
  },
  $utils: {
    get: () => {
      return utils;
    },
  },
  $baseURL: {
    get: () => process.env.BASE_URL
  }
});

Vue.use(Alert, config);
Vue.use(Axios, config);
Vue.use(Validator, config);

(async() => {
  const store = await Store(Vue)
  const router = Router({ Vue, store });

  Vue.use(
    VueGtag,
    config.ga,
    router
  );

  new Vue({
    router,
    store,
    vuetify: Vuetify(Vue),
    render: (h) => h(App),
  }).$mount("#app");

  Vue.use(ErrorHandler, { store, router })
})();
