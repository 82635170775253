import Vuex from "vuex";
import userContext from "./userContext"
import upStart from "./upstart"
import redirection from "./redirection"
import appState from "./appState"
import get from "lodash/get"

export default async (Vue) => {
  Vue.use(Vuex);
  const store = new Vuex.Store({
    modules:{
      upStart: upStart(Vue),
      userContext: userContext(Vue),
      redirection: redirection(Vue),
      appState: appState()
    }
  });
  Vue.store = store;

  //load server configs from app start-up
  //store.commit("appState/appLoading", true)
  await store.dispatch("upStart/initLoad");
  Object.defineProperties(Vue.prototype, {
    $server_configs: {
      get: function() {
        return store.getters["upStart/getConfigs"];
      },
    },
  });

  Vue.prototype.$shop = (path) => {
    let val = Vue.prototype.$server_configs.shopStore
    if(path && path.length){
      val = get(val, path)
    }

    return val
  }
  //store.commit("appState/appLoading", false)

  Object.defineProperties(Vue.prototype, {
    $user: {
      get: function() {
        return store.getters["userContext/user"]();
      },
    },
  });

  return store;
};
