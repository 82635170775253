<template>
  <v-layout>
    <v-navigation-drawer v-model="navShow" app :mini-variant.sync="mini">
      <v-list-item color="grey lighten-5" class="px-2">
        <v-list-item-avatar>
          <v-icon>$logoSingle</v-icon>
        </v-list-item-avatar>
        <v-list-item-title style="line-height: 2.2">
          List of Content
        </v-list-item-title>
        <v-btn icon @click.stop="click_arrow">
          <font-awesome-icon
            :icon="['fas', 'arrow-circle-left']"
            style="font-size: 21px"
          ></font-awesome-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link v-for="(menu_item, index) in this.menus_items" :key="index" :href="menu_item.href" :title="menu_item.title">
          <v-list-item-title>
            {{ menu_item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-layout class="pa-3">
        <v-flex xs12 sm12 md12 lg10 xl8>
          <v-btn fab bottom right fixed v-if="!this.$vuetify.breakpoint.lgAndUp" @click="click_float_btn" :large="$vuetify.breakpoint.mdAndDown">
            <v-icon>$logoSingle</v-icon>
          </v-btn>
          <router-view @populated="menus_populated"/>
        </v-flex>
      </v-layout>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  data: function(){
    return {
      mini: false,
      navShow: this.$vuetify.breakpoint.lgAndUp,
      menus_items: []
    };
  },
  methods:{
    click_arrow(){
      if(!this.$vuetify.breakpoint.lgAndUp){
        this.navShow = false;
      }
      else{
        this.mini = !this.mini
      }
    },
    click_float_btn(){
      this.navShow = !this.navShow;
      this.mini = false;
    },
    menus_populated(menus){
      this.menus_items = this.menus_items.concat(menus)
    }
  }
};
</script>

<style scoped>

</style>
