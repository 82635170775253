export default ({ Vue, store }) => {
  return {
    path: "/sso",
    name: "sso",
    meta: {
      title: "Single Sign On",
    },
    beforeEnter: async (to, from, next) => {
      store.commit("appState/appLoading", true);
      const typesMap = {
        signin: "index",
        signup: "signup"
      };
      const type = to.query.type || Object.keys(typesMap)[0];
      const redirect = to.query.redirect || store.getters["redirection/URL"]();
      if (redirect) {
        store.commit("redirection/setURL", redirect);
      }
      let finalRedirect;
      if (store.getters["userContext/isLoggedIn"]()) {
        if (redirect && !Vue.prototype.$utils.isOwnOrigin(redirect)) {
          try{
            const { data: res } = await Vue.$http
              .post("/auth/sign", {
              redirect,
            });

            if (res.redirect) {
              finalRedirect = res.redirect;
            } else {
              finalRedirect = { name: "userIndex" };
            }
            store.commit("redirection/delURL");
          }
          catch (error){
            if (error.response) {
              const { status } = error.response;
              if (status === 401) {
                store.commit("userContext/signout");
                finalRedirect = { name: typesMap[type] };
              }
            }
          }
        } else {
          finalRedirect = redirect ? redirect : { name: "userIndex" };
          store.commit("redirection/delURL");
        }
      } else {
        finalRedirect = { name: typesMap[type] };
      }

      if (typeof finalRedirect === "object") {
        next({ ...finalRedirect, replace: true });
        return store.commit("appState/appLoading", false);
      }

      window.location.href = finalRedirect;
      next();
    },
  };
};
