export default {
  webapi: {
    baseUrl: "https://usercenter.api.unibuying.com",
  },
  storage:{
    key: "accounts.unibuying"
  },
  ga:{
    config: {
      id: "G-NWXWZ1BWBT"
    },
    appName: "Unibuying Accounts",
    pageTrackerScreenviewEnabled: true
  }
};
