var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[(this.isPageLoading)?_c('v-skeleton-loader',{staticClass:"mt-2",attrs:{"type":"article, list-item-two-line, paragraph, actions"}}):_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center text-uppercase"},[_vm._v(" Sign Up ")]),_c('v-sheet',{staticClass:"font-italic font-weight-light",staticStyle:{"font-size":"small"}},[_vm._v(" If you already signed up in "),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(this.$shop("name")))]),_vm._v(" App, please feel free to use the account to "),_c('router-link',{attrs:{"to":"signin"}},[_vm._v("sign in")]),_vm._v(". ")],1),_c('ValidationObserver',{ref:"observer"},[_c('v-form',{attrs:{"disabled":_vm.isSubmitting}},[_c('ValidationProvider',{attrs:{"name":"login email address","rules":_vm.rules.id,"customMessages":_vm.valiMessages.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","required":"","error-messages":errors,"hint":"Please fill in your login email address","autocomplete":"off"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"login password","rules":_vm.rules.password,"customMessages":_vm.valiMessages.password,"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":"password","required":"","error-messages":errors,"counter":"","hint":"Please fill in your login password"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"confirm password","rules":_vm.rules.confirm_password,"customMessages":_vm.valiMessages.confirm_password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm Password","type":"password","required":"","error-messages":errors,"hint":"Please fill in your login password again"},model:{value:(_vm.model.confirm_password),callback:function ($$v) {_vm.$set(_vm.model, "confirm_password", $$v)},expression:"model.confirm_password"}})]}}])}),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6","lg":"8"}},[_vm._v(" I already had an account, please "),_c('router-link',{staticClass:"ml-2",attrs:{"to":"signin"}},[_vm._v("sign in")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-checkbox',{attrs:{"label":"Opt in news letters"},model:{value:(_vm.model.opt_in_news),callback:function ($$v) {_vm.$set(_vm.model, "opt_in_news", $$v)},expression:"model.opt_in_news"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"terms & policies","rules":_vm.rules.agree_terms,"customMessages":_vm.valiMessages.agree_terms},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"type":"checkbox","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the "),_c('router-link',{attrs:{"to":{ name: 'services' },"target":"_blank"}},[_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Terms of Services & Privacy Statements ")])])],1)]},proxy:true}],null,true),model:{value:(_vm.model.agree_terms),callback:function ($$v) {_vm.$set(_vm.model, "agree_terms", $$v)},expression:"model.agree_terms"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" Create Account ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }