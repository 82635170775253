<template>
  <v-flex>
    <v-skeleton-loader type="article, list-item-two-line, paragraph, actions" v-if="this.isPageLoading" class="mt-2">
    </v-skeleton-loader>
    <v-card v-else flat>
      <v-card-title class="justify-center text-uppercase">
        Bind Your Email
      </v-card-title>
      <v-alert border="top" type="info" outlined>
        For security reasons we kindly ask you to bind your email before proceed
      </v-alert>
      <ValidationObserver ref="observer">
        <v-form :disabled="isVerifying">
          <ValidationProvider v-slot="{ errors }" name="login username" :rules="rules.username" :customMessages="{required: 'please fill in {_field_}'}">
            <v-text-field
              v-model.trim="model.username"
              label="Username"
              :disabled="isSubmitting || verifyMode"
              required
              :error-messages="errors"
              hint="Please fill in your login username"
              autocomplete="off"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" name="login password" :rules="rules.password" :customMessages="{required: 'please fill in {_field_}'}">
            <v-text-field
              v-model="model.password"
              label="Password"
              :disabled="isSubmitting || verifyMode"
              type="password"
              required
              :error-messages="errors"
              counter
              hint="Please fill in your login password"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" name="email address" :rules="rules.email" :customMessages="valiMessages.id">
            <v-text-field
              v-model="model.email"
              label="Email address"
              :disabled="isSubmitting || verifyMode"
              required
              :error-messages="errors"
              hint="Please fill in your email address to bind"
            ></v-text-field>
          </ValidationProvider>

          <v-expand-transition>
            <transition-group>
              <template v-if="verifyMode">
                <v-alert
                  key="alert"
                  icon="envelopeOpenText"
                  prominent
                  text
                  type="info"
                  transition="expand-transition"
                >
                  <template slot="prepend">
                    <i class="v-icon notranslate v-alert__icon mdi mdi-shield-lock-outline theme--dark info--text">
                      <font-awesome-icon :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
                    </i>
                  </template>
                  <span>
                    An email contained verification code has sent to <a target="_blank" :href="`mailto:${this.model.email}`">{{ model.email }}</a>,
                    please check your inbox and confirm the verification code.
                  </span>
                  <v-divider class="my-4 info" style="opacity: 0.22"/>
                  <span class="font-italic text-caption">
                    Note*: Don't forget to check your junk box if you can't find the email.
                  </span>
                </v-alert>
                <ValidationProvider key="model.verify_code" v-slot="{ errors }" name="verification code" :rules="rules.verify_code" :customMessages="{required: 'please fill in the {_field_} received'}">
                  <v-text-field
                    v-model.trim="verify_code"
                    label="Verification code"
                    required
                    :error-messages="errors"
                    hint="Please fill in the verification code received"
                    autocomplete="off"
                  ></v-text-field>
                </ValidationProvider>
              </template>
            </transition-group>
          </v-expand-transition>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" outlined :loading="isSubmitting" :disabled="resendCountDown > 0">
          <template v-if="verifyMode">
            Re-Send
          </template>
          <template v-else>
            Send Verification Code
          </template>
          <template v-if="resendCountDown > 0">
            ({{ resendCountDown }} s)
          </template>
        </v-btn>

        <template v-if="verifyMode">
          <v-btn @click="verify" outlined :loading="isVerifying"> Verify </v-btn>
          <v-btn @click="change" outlined> Change email </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "BindEmail",
  data: function(){
    return {
      isPageLoading: true,
      isSubmitting: false,
      isVerifying: false,
      verifyMode: false,
      resendCountDown: 0,
      model: {
        username: this.$route.query.username,
        email: null,
        password: null
      },
      verify_code: null,
      rules: {
        username: {
          required: true
        },
        password: {
          required: true
        },
        verify_code: {
          required: true
        }
      },
      valiMessages: null
    }
  },
  methods: {
    async submit(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isSubmitting = true
          const { data: res } = await this.$http.post("/user/bindEmail", {
            username: this.model.username,
            email: this.model.email,
            password: this.model.password
          });
          if (!res.errors || !res.errors.length){
            this.resetCountDown()
            this.verifyMode = true
          }

          this.verify_code = null
          this.isSubmitting  =false
        }
      })
    },
    resetCountDown(){
      this.resendCountDown = 60;
      const timer = setInterval(() => {
        this.resendCountDown--;

        if (this.resendCountDown === 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
    async verify(){
      return this.$refs.observer.validate().then(async valid => {
        if(valid){
          this.isVerifying = true
          const username = this.model.username
          const { data: res } = await this.$http.post("/user/activate", {
            username,
            verify_code: this.verify_code
          });

          if (!res.errors || !res.errors.length){
            if(this.$route.query.redirect){
              location.href = this.$route.query.redirect
            }
            else{
              await this.$router.replace({
                name: "notice",
                params: {
                  title: "Email Bound",
                  type: "success",
                  content:
                    "You account has been successfully bound to the email.",
                  actions: ["signin"],
                },
              });
            }
          }

          this.isVerifying = false
        }
      })
    },
    change(){
      this.verify_code = null
      this.verifyMode = false
    }
  },
  async mounted(){
    this.isPageLoading = true
    const { data: rules } = await this.$http.post("/user/validationRules",{
      vType: "vee",
    });
    this.rules.email = rules.id

    this.valiMessages = this.$utils.normalizeRuleMessages(this.rules)
    this.isPageLoading = false
  }
};
</script>

<style scoped>

</style>
